import { IImage, INavBarOptionItemSearchModel, INavBarSetting, IUpsertNavBarSettingRequest } from "@/interfaces/Responses/Responses";
import { INavBarOption, IOperationResult, IOperationResultT } from '@/Templates/interfaces/templatesInterfaces';
import useAPI from '@/Templates/lib/customHooks/useAPI';

export const useGetMerhcantNavBarSettingViewModelApi = (signal?: AbortSignal) => useAPI<IOperationResultT<IImage[]>>(`${process.env.BASE_API_URL}/api/FrontEndSetting/GetMerhcantNavBarSettingViewModel`, {
    signal: signal,
});

export const useGetNavBarSettingsApi = (signal?: AbortSignal) => useAPI<IOperationResultT<INavBarSetting>>(`${process.env.BASE_API_URL}/api/FrontEndSetting/GetNavBarSettings`, {
    signal: signal,
});

export const useGetNavBarOptionsApi = (optionIds: BigInt[], signal?: AbortSignal) => useAPI<IOperationResultT<INavBarOption[]>>(`${process.env.BASE_API_URL}/api/FrontEndSetting/GetNavBarOptions`, {
    method: 'GET',
    body: { optionIds: optionIds },
    signal: signal,
});

export const useGetNavBarOptionItemSearchModelApi = (signal?: AbortSignal) => useAPI<IOperationResultT<INavBarOptionItemSearchModel>>(`${process.env.BASE_API_URL}/api/FrontEndSetting/GetNavBarOptionItemSearchModel`, {
    signal: signal,
});

export const useUpsertNavBarSettingApi = (request: IUpsertNavBarSettingRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/FrontEndSetting/UpsertNavBarSetting`, {
    method: 'POST',
    body: request,
});

export const useGetDefaultPortalStyleTemplateApi = (type: string, name: string, signal?: AbortSignal) => useAPI<IOperationResultT<string>>(`${process.env.BASE_API_URL}/api/FrontEndSetting/GetDefaultPortalStyleTemplate`, {
    method: 'GET',
    signal: signal,
    body: {
        type: type,
        name: name,
    },
});