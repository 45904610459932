import { MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from "@/Templates/enums/templateEnums";
import dynamic from "next/dynamic";
import React, { useMemo } from "react";
import { TemplateProps } from "../TemplateProps";
import LoadingComponent from "./LoadingCompoent";

const ScrollToTopBtn: React.FC<TemplateProps> = ({
    BASE_PATH,
    portalStyleSettings,
    isPreviewMode,
    merchantId,
    config,
    translate,
    portalOptionSettingViewModel,
    messageApi,
    deviceType
}) => {
    const portalStyle = portalStyleSettings.find(x => x.type === MerchantPortalStyleSettingType.Components && x.name === MerchantPortalStyleShopComponents.ScrollToTopBtn);

    const TemplateComponent = useMemo(() => {
        return dynamic<TemplateProps>(() =>
            import(`../Shop/Components/ScrollToTopBtn/Template_${portalStyle?.style || '0'}.tsx`),
            {
                loading: () => <LoadingComponent />,
                ssr: false
            }
        );
    }, [portalStyle]);

    return <TemplateComponent
        deviceType={deviceType}
        BASE_PATH={BASE_PATH}
        isPreviewMode={isPreviewMode}
        merchantId={merchantId}
        config={config}
        translate={translate}
        portalOptionSettingViewModel={portalOptionSettingViewModel}
        portalStyleSettings={portalStyleSettings}
        messageApi={messageApi}
    />;
};

export default ScrollToTopBtn;