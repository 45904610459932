

export enum ThirdPartyLogisticQueryStatusResponseType {
    Normal = 'Normal',
    Redirect = 'Redirect',
}

export enum LogisticOrderType {
    /// <summary>
    /// 正向物流
    /// </summary>
    Forward = 'Forward',
    /// <summary>
    /// Refund Item
    /// </summary>
    Reverse = 'Reverse',
}

export enum LogisticOrderStatus {
    // 正向物流状态
    Created = 'Created', // 已建立
    DuringShipping = 'DuringShipping', // 運送中
    ArrivalWaitForPickup = 'ArrivalWaitForPickup', // 已送達等待取貨
    ExpiredNotPickup = 'ExpiredNotPickup', // 超時未取貨
    ShippingDispute = 'ShippingDispute', // 物流爭議（正向物流）
    BuyerNotPickup = 'BuyerNotPickup', // 買家未取貨
    ReturnToLogisticsCenter = 'ReturnToLogisticsCenter', // 未取貨退回物流中心

    // 逆向物流状态
    DuringRefundShipping = 'DuringRefundShipping', // 退貨中
    RefundArrivalWaitForPickup = 'RefundArrivalWaitForPickup', // 退貨已送達等待取貨
    RefundShippingDispute = 'RefundShippingDispute', // 退貨物流爭議（逆向物流）
    RefundExpiredNotPickup = 'RefundExpiredNotPickup', // 退貨超時未取貨
    MerchantNotPickup = 'MerchantNotPickup', // 商家未取貨（退貨商品）
    RefundReturnToLogisticsCenter = 'RefundReturnToLogisticsCenter', // 退貨未取貨退回物流中心

    // 通用状态
    Done = 'Done', // 已完成
    Cancel = 'Cancel', // 已取消
    Unknow = 'Unknow', // 未知 [需人工處理]
}

export enum LogisticReceviePointType {
    HomeDelivery = 'HomeDelivery',
    ConvenienceStore = 'ConvenienceStore',
    ProviderLocalStore = 'ProviderLocalStore',
}

export enum LogisticsMainType {
    None = 'None',
    AllInOneIntegration = 'AllInOneIntegration',
    ConvenienceStore = 'ConvenienceStore',
    HomeDelivery = 'HomeDelivery',
    MerchantHandle = 'MerchantHandle',
}

export enum LogisticSenderPointType {
    /// <summary>
    /// 到家收貨
    /// </summary>
    HomeDelivery = 'HomeDelivery',
    /// <summary>
    /// 超商
    /// </summary>
    ConvenienceStore = 'ConvenienceStore',
    /// <summary>
    /// 物流商地區商店
    /// </summary>
    ProviderLocalStore = 'ProviderLocalStore',
}
