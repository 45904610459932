import { MerchantRoleEnum } from "@/enums/Enums";
import { IAlterUserRolesRequest } from "@/interfaces/Requests/Permission";
import { IOperationResult, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import useAPI from '@/Templates/lib/customHooks/useAPI';

export const useGetMyRolesApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<MerchantRoleEnum[]>>(`${process.env.BASE_API_URL}/api/Permission/GetMyRoles`, { signal });

export const useGetOwnRolesApi = (userId: string, signal?: AbortSignal) =>
    useAPI<IOperationResultT<MerchantRoleEnum[]>>(`${process.env.BASE_API_URL}/api/Permission/GetOwnRoles`, {
        method: 'GET',
        body: { userId },
        signal
    });

export const useGetCanOperateRolesApi = (userId: string, signal?: AbortSignal) =>
    useAPI<IOperationResultT<MerchantRoleEnum[]>>(`${process.env.BASE_API_URL}/api/Permission/GetCanOperateRoles`, {
        method: 'GET',
        body: { userId },
        signal
    });

export const useAlterUserRolesApi = (request: IAlterUserRolesRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Permission/AlterUserRoles`, {
        method: 'POST',
        body: request,
        signal
    });