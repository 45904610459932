import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { Flex, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import DataStatistics from './Components/DataStatistics';
import RecentOrdersChart from './Components/RecentOrdersChart';
import { DataAnalysisPageContext, DataAnalysisPageProvider } from './Contexts/DataAnalysisPageContext';

const DataAnalysisContent: React.FC = () => {
    const { translate } = useContext(TranslationContext);
    const { abortController } = useContext(DataAnalysisPageContext);
    useEffect(() => {
        abortController.current = new AbortController();
        return () => {
            abortController.current.abort();
        };
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <Flex justify='center'>
                <Typography.Title level={3}>{translate('Data Analysis')}</Typography.Title>
            </Flex>
            <DataStatistics />
            <RecentOrdersChart />
        </div>
    );
};

const DataAnalysisPage: React.FC = () => {


    return (
        <DataAnalysisPageProvider>
            <DataAnalysisContent />
        </DataAnalysisPageProvider>
    );
};

export default DataAnalysisPage;
