import { IAddMerchantThirdPartyPaymentSettingRequest } from "@/interfaces/Requests/Requests";
import { IBackOfficeMerchantThirdPartyPaymentSettingViewModel } from "@/interfaces/Responses/Responses";
import { IThirdPartyPaymentSetting } from "@/interfaces/Responses/ThirdPartyPayment";
import { useAddMerchantThirdPartyPaymentSettingApi, useGetThirdPartyPaymentSettingsApi } from "@/lib/api/thirdPartyPayments";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Empty, Form, Input, Row, Select } from "antd";
import Title from "antd/es/typography/Title";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { useMutation } from "react-query";
import InformationPopover from "./Popovers/InformationPopver";

export interface IAddPaymentSettingProps {
    ownRecords: IBackOfficeMerchantThirdPartyPaymentSettingViewModel[] | undefined;
    onRefresh: () => void;
    onClose: () => void;
}

export interface IAddPaymentSettingRef {
    refresh: () => void;
}

const AddPayment = React.forwardRef((props: IAddPaymentSettingProps, ref: React.ForwardedRef<IAddPaymentSettingRef | undefined>) => {
    const [form] = Form.useForm();
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [avaliabeTpps, setAvaliabeTpps] = useState<IThirdPartyPaymentSetting[] | undefined>(undefined);

    const { mutate: fetchSettings } = useMutation(async () => await useGetThirdPartyPaymentSettingsApi(), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                const filteredTpps = response.result?.filter(tpp =>
                    !props.ownRecords?.find(record => record.thirdPartySettingId.toString() === tpp.id.toString())
                ) || [];
                setAvaliabeTpps(filteredTpps);
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const { mutate: addSetting, isLoading } = useMutation(async (request: IAddMerchantThirdPartyPaymentSettingRequest) => await useAddMerchantThirdPartyPaymentSettingApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                props.onRefresh();
                props.onClose();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const formValues = Form.useWatch([], form);
    const selectedTppId = formValues?.selectedTpp;
    const selectedTpp = avaliabeTpps?.find(tpp => tpp.id.toString() === selectedTppId);

    useEffect(() => {
        fetchSettings();
    }, []);

    useImperativeHandle(ref, () => ({
        refresh: () => {
            form.resetFields();
            fetchSettings();
        }
    }));

    const handleSubmit = () => {
        if (!selectedTpp || !merchantId) return;

        const merchantSettings = selectedTpp.merchantFillFieldsForBasicInfo.reduce((acc, field) => {
            acc[field] = formValues[field];
            return acc;
        }, {} as Record<string, string>);

        const request: IAddMerchantThirdPartyPaymentSettingRequest = {
            settingId: selectedTpp.id,
            setting: merchantSettings
        };
        addSetting(request);
    };

    if (!avaliabeTpps) return null;

    if (avaliabeTpps.length === 0) {
        return (
            <Row justify='center'>
                <Empty />
            </Row>
        );
    }

    return (
        <>
            <Row justify='center'>
                <Title level={4}>
                    {translate(`Available`)} {translate(`Payment`)}
                </Title>
            </Row>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                {selectedTpp && (
                    <Row justify="center" style={{ marginTop: 8 }}>
                        <InformationPopover providerName={selectedTpp.providerName} />
                    </Row>
                )}
                <Form.Item
                    name="selectedTpp"
                    label={
                        <>
                            {`${translate('Select')} ${translate('Payment')}`}
                        </>
                    }
                >
                    <Select>
                        {avaliabeTpps.map((tpp) => (
                            <Select.Option key={tpp.id.toString()} value={tpp.id.toString()}>
                                {tpp.providerName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {selectedTpp && selectedTpp.merchantFillFieldsForBasicInfo.map((field) => (
                    <Form.Item
                        key={field}
                        name={field}
                        label={translate(field)}
                    >
                        <Input />
                    </Form.Item>
                ))}

                <Row justify='center'>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {translate('Add')}
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </>
    );
});

export default AddPayment;