import { isEqual } from 'lodash';

export class ObjectComparisonHelper {
    /**
     * 比較兩個對象是否相等
     * @param obj1 第一個對象
     * @param obj2 第二個對象
     * @returns 如果對象相等返回 true，否則返回 false
     */
    static areObjectsEqual(obj1: any, obj2: any): boolean {
        return isEqual(obj1, obj2);
    }

    /**
     * 深度比較兩個對象
     * @param obj1 第一個對象
     * @param obj2 第二個對象
     * @returns 如果對象完全相等返回 true，否則返回 false
     */
    static deepCompare(obj1: any, obj2: any): boolean {
        // 檢查兩個參數是否都是對象
        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
            return obj1 === obj2;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // 如果鍵的數量不同，對象就不相等
        if (keys1.length !== keys2.length) {
            return false;
        }

        // 遍歷所有鍵，比較值
        for (const key of keys1) {
            if (!obj2.hasOwnProperty(key)) {
                return false;
            }

            // 遞歸比較嵌套對象
            if (!this.deepCompare(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }

    /**
     * 比較兩個對象的特定屬性
     * @param obj1 第一個對象
     * @param obj2 第二個對象
     * @param properties 要比較的屬性數組
     * @returns 如果指定的屬性都相等返回 true，否則返回 false
     */
    static compareSpecificProperties(obj1: any, obj2: any, properties: string[]): boolean {
        for (const prop of properties) {
            if (!this.deepCompare(obj1[prop], obj2[prop])) {
                return false;
            }
        }
        return true;
    }
}