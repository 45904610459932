import logo from '@/assets/Silent_Mystic_Mini_Logo.png';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { DeviceType } from '@/Templates/enums/templateEnums';
import { ConfigProvider, Flex, Spin } from 'antd';
import { Locale } from 'antd/lib/locale';
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import zhTW from 'antd/locale/zh_TW';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { GlobalContext } from '../../lib/contexts/GlobalContext';
import AppFooter from './Components/AppFooter';
import MyRoutes from './Components/MyRoutes';
import NavBar from './Components/NavBar';


const App: React.FC = () => {
  const { messageApi, setNavBarHeight, isLogin, isPageLoading, setDeviceType } = useContext(GlobalContext);
  const { i18nLanguage } = useContext(TranslationContext);
  const [locale, setLocale] = React.useState<Locale>(enUS);

  useEffect(() => {
    const handleResize = () => {
      if (window.screen.width <= 768) {
        setDeviceType(DeviceType.Mobile);
      } else if (window.screen.width <= 1024) {
        setDeviceType(DeviceType.LapTop);
      } else {
        setDeviceType(DeviceType.Desktop);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [setDeviceType]);

  useEffect(() => {
    switch (i18nLanguage) {
      case 'zh_TW':
        setLocale(zhTW);
        break;
      case 'zh_CN':
        setLocale(zhCN);
        break;
      default:
        setLocale(enUS);
        break;
    }
  }, [i18nLanguage]);

  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <link rel="icon" type="image/*" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>MicroShop BackOffice</title>
      </Helmet>
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: "orange",
          },
        }}
      >
        {isLogin && <NavBar />}
        {/* Page Loading Cover */}
        <Flex id="pageLoadingCover" hidden={!isPageLoading} justify="center" align="center" style={{ zIndex: 9999, position: "fixed", height: '100vh', width: '100vw', backgroundColor: 'whitesmoke', opacity: 0.5 }}>
          <Spin >
            <div id="loading"></div>
          </Spin>
        </Flex>
        <div style={{ maxWidth: '1024px', minHeight: '90vh', margin: '0 auto' }}>
          <MyRoutes />
        </div>
        <AppFooter></AppFooter>
      </ConfigProvider>
    </>
  );
};

export default App;