import { ItemContext } from "@/lib/contexts/ItemContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { IItemViewModel } from "@/Templates/interfaces/templatesInterfaces";
import { useContext, useEffect, useState } from "react";
import ItemDetail from "./ItemDetail";

export interface IPreviewItemProps {
}
const PreviewItem: React.FC<IPreviewItemProps> = () => {
    const { translate } = useContext(TranslationContext);
    const { itemViewModel, itemSpecs, itemConverImageFiles } = useContext(ItemContext);
    const [item, setItem] = useState<IItemViewModel>({
        itemConverImagePaths: [],
        itemTags: [],
        id: BigInt(0),
        name: '',
        title: '',
        briefly: '',
        description: '',
        createdDate: '',
        alterDate: '',
        isOnShelf: false,
        isDelete: false,
        minPrice: 0,
        maxPrice: 0,
        isSoldOut: false
    });
    useEffect(() => {
        setItem({
            ...item,
            name: itemViewModel.name,
            title: itemViewModel.title,
            briefly: itemViewModel.briefly,
            description: itemViewModel.description,
            itemConverImagePaths: itemConverImageFiles.map((file) => file.thumbUrl ? file.thumbUrl : file.url ? file.url : ''),
            itemTags: itemViewModel.itemTags,
        });
    }, []);

    return (
        <div>
            <ItemDetail item={item} itemSpecs={itemSpecs} />
        </div>
    );
};

export default PreviewItem;
