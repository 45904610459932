import { LogisticOrderType } from "@/enums/ThirdPartyLogistic";
import { IAddMerchantThirdPartyLogisticSettingRequest, IAlterMerchantThirdPartyLogisticSettingRequest, IGetBackOfficeOrderViewModelRequest, IGetThirdPartyOrdersByPlatformOrderRequest, IQueryThirdPartyOrderStatusRequest } from "@/interfaces/Requests/Requests";
import { ICreateThirdPartyLogisticOrderRequest } from "@/interfaces/Requests/ThirdPartyLogistic";
import { IBackOfficeMerchantThirdPartyLogisticSettingViewModel, IBackOfficeOrderViewModel } from "@/interfaces/Responses/Responses";
import { IThirdPartyLogisticCreateRespnse, IThirdPartyLogisticCreateStickerResponse, IThirdPartyLogisticOrderViewModel, IThirdPartyLogisticQueryStatusResponse, IThirdPartyLogisticSetting } from "@/interfaces/Responses/ThirdPartyLogistic";
import { IMemberLogisticOption, IOperationResult, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import useAPI from '@/Templates/lib/customHooks/useAPI';

export const useGetThirdPartyLogisticsApi = (logisticOrderType?: LogisticOrderType) => useAPI<IOperationResultT<IThirdPartyLogisticSetting[]>>(`${process.env.BASE_API_URL}/api/ThirdPartyLogistic/GetThirdPartyLogisticSettings`, {
    method: 'GET',
    body: { type: logisticOrderType }
});

export const useGetThirdPartyLogisticSettingApi = (settingId: BigInt) => useAPI<IOperationResultT<IThirdPartyLogisticSetting>>(`${process.env.BASE_API_URL}/api/ThirdPartyLogistic/GetThirdPartyLogisticSetting?settingId=${settingId.toString()}`);

export const useGetBackOfficeMerchantThirdPartyLogisticSettingViewModelsApi = (signal?: AbortSignal) => useAPI<IOperationResultT<IBackOfficeMerchantThirdPartyLogisticSettingViewModel[]>>(`${process.env.BASE_API_URL}/api/ThirdPartyLogistic/GetBackOfficeMerchantThirdPartyLogisticSettingViewModels`, {
    signal: signal

});

export const useAddMerchantThirdPartyLogisticSettingApi = (request: IAddMerchantThirdPartyLogisticSettingRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ThirdPartyLogistic/AddMerchantThirdPartyLogisticSetting`, {
    method: 'POST',
    body: request
});

export const useAlterMerchantThirdPartyLogisticSettingApi = (request: IAlterMerchantThirdPartyLogisticSettingRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ThirdPartyLogistic/AlterMerchantThirdPartyLogisticSetting`, {
    method: 'POST',
    body: request
});

export const useGetBackOfficeOrderViewModelApi = (request: IGetBackOfficeOrderViewModelRequest) => useAPI<IOperationResultT<IBackOfficeOrderViewModel>>(`${process.env.BASE_API_URL}/api/Order/GetBackOfficeOrderViewModel`, {
    method: 'GET',
    body: request
});

export const useCreateThirdPartyLogisticOrderApi = (request: ICreateThirdPartyLogisticOrderRequest) => useAPI<IOperationResultT<IThirdPartyLogisticCreateRespnse>>(`${process.env.BASE_API_URL}/api/ThirdPartyLogistic/CreateOrder`, {
    method: 'POST',
    body: request
});

export const useGetThirdPartyOrdersByPlatformOrderApi = (request: IGetThirdPartyOrdersByPlatformOrderRequest) => useAPI<IOperationResultT<IThirdPartyLogisticOrderViewModel[]>>(`${process.env.BASE_API_URL}/api/ThirdPartyLogistic/GetThirdPartyOrdersByPlatformOrder`, {
    method: 'GET',
    body: request
});

export const useQueryLogisticOrderStatusApi = (request: IQueryThirdPartyOrderStatusRequest) => useAPI<IOperationResultT<IThirdPartyLogisticQueryStatusResponse>>(`${process.env.BASE_API_URL}/api/ThirdPartyLogistic/QueryThirdPartyOrderStatus`, {
    method: 'POST',
    body: request
});

export const useGetMemberLogisticOptionApi = (optionId: BigInt) => useAPI<IOperationResultT<IMemberLogisticOption>>(`${process.env.BASE_API_URL}/api/ThirdPartyLogistic/GetMemberLogisticOption`, {
    method: 'GET',
    body: { optionId }
});

export const useCreateStickerInfoApi = (thirdPartyOrderId: BigInt) => useAPI<IOperationResultT<IThirdPartyLogisticCreateStickerResponse>>(`${process.env.BASE_API_URL}/api/ThirdPartyLogistic/CreateStickerInfo`, {
    method: 'GET',
    body: { thirdPartyOrderId: thirdPartyOrderId }
});