import { ImageUseageType } from '@/enums/Enums';
import { IAlterPortalStyleRequest } from '@/interfaces/Requests/Merchant';
import { useGetNavBarOptionItemSearchModelApi } from '@/lib/api/frontendsettings';
import { useUploadImageApi } from '@/lib/api/images';
import { useAlterPortalStyleApi } from '@/lib/api/merchants';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { GenereateHelper } from '@/lib/helpers/GenerateHelper';
import {
    ConfigFieldType,
    DeviceType,
    MerchantPortalStyleSettingType,
    MerchantPortalStyleShopComponents,
    NavBarClickType,
} from '@/Templates/enums/templateEnums';
import { IConfigField, INavBarOption } from '@/Templates/interfaces/templatesInterfaces';
import { CloseCircleOutlined, EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Form, Input, InputNumber, Modal, Select, Tree, Upload } from 'antd';
import { Flex } from 'antd/lib';
import JSONBig from 'json-bigint';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, UseMutationResult, useQuery } from 'react-query';

export interface StyleConfigSelectorProps {
    type: MerchantPortalStyleSettingType;
    name: string;
    selectedStyle: number;
    styleConfig: Record<string, string>;
    configFields: Array<IConfigField>;
    onStyleChange: (value: number) => void;
    onConfigChange: (key: string, value: string) => void;
    styleOptions: Array<{ value: number; label: string }>;
    abortSignal?: AbortSignal;
}

export enum ItemSearchSubType {
    Category = 'Category',
    Item = 'Item',
    Tag = 'Tag',
}

const StyleConfigSelector: React.FC<StyleConfigSelectorProps> = ({
    type,
    name,
    selectedStyle,
    styleConfig,
    configFields,
    onStyleChange,
    onConfigChange,
    styleOptions,
    abortSignal,
}) => {
    const {
        generateHelper,
        deviceType,
        messageApi,
        merchantId,
        getMerchantPortalStyleSettings,
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [itemSearchSubType, setItemSearchSubType] = useState<ItemSearchSubType>(ItemSearchSubType.Category);
    const alterPortalStyleApi = useMutation(
        async (request: IAlterPortalStyleRequest) => useAlterPortalStyleApi(request),
        {
            onSuccess: () => {
                getMerchantPortalStyleSettings.mutate({
                    merchantId: merchantId!,
                });
                setDrawerVisible(false);
                messageApi.success(translate('Operation successful'));
            },
            onError: () => {
                messageApi.error(translate('Operation failed'));
            },
        }
    );
    const uploadImagesMutation = useMutation<
        string[],
        Error,
        { files: File[]; fieldKey: string }
    >(
        async ({ files, fieldKey }) => {
            const uploadPromises = files.map(async (file) => {
                const formData = new FormData();
                formData.append('File', file);
                formData.append('UseageType', ImageUseageType.ForPreview);
                formData.append('ReferenceId', merchantId ? merchantId.toString() : '');
                const response = await useUploadImageApi(formData);
                if (response.isSuccess && response.result) {
                    return response.result;
                }
                throw new Error('Image upload failed');
            });

            const results = await Promise.all(uploadPromises);
            return results;
        },
        {
            onSuccess: (result, { fieldKey }) => {
                const newUrls = result.map((url: string) => url);
                const currentUrls = JSON.parse(styleConfig[fieldKey] || '[]');
                const updatedUrls = [...currentUrls, ...newUrls];
                onConfigChange(fieldKey, JSON.stringify(updatedUrls));
                messageApi.success(translate('Image upload successful'));
            },
            onError: (error) => {
                messageApi.error(translate('Image upload failed'));
                console.error('Upload error:', error);
            },
        }
    );
    const handleSave = () => {
        alterPortalStyleApi.mutate({
            type: type,
            name: name,
            style: selectedStyle,
            styleSetting: styleConfig,
        });
    };

    const handleDefault = () => {
        Modal.confirm({
            title: translate('Confirm'),
            content: translate(
                'Are you sure you want to use default settings? This will overwrite current configurations.'
            ),
            onOk: () => {
                configFields.forEach((field) => {
                    onConfigChange(field.key, field.defaultValue);
                });
                onStyleChange(styleOptions[0].value);
                messageApi.success(translate('Default settings applied'));
            },
            onCancel: () => {
                console.log('Cancelled using default settings');
            },
        });
    };

    const [navBarOptions, setNavBarOptions] = useState<INavBarOption[]>([]);

    useEffect(() => {
        if (type === MerchantPortalStyleSettingType.Components && name === MerchantPortalStyleShopComponents.NavBar) {
            try {
                const parsedOptions = JSONBig.parse(styleConfig.navBarOptions || '[]');
                setNavBarOptions(parsedOptions);
            } catch (error) {
                console.error('解析 NavBarOptions 時出錯:', error);
                setNavBarOptions([]);
            }
        }
    }, [type, name, styleConfig]);

    return (
        <>
            <Button style={{ margin: '20px' }} type="default" onClick={() => setDrawerVisible(true)}>
                {translate('Configure')}
            </Button>
            <Drawer
                title={translate('Style Configuration')}
                placement={'right'}
                onClose={() => setDrawerVisible(false)}
                open={drawerVisible}
                width={deviceType === DeviceType.Mobile ? '100%' : undefined}
            >
                <Form layout="vertical">
                    <Form.Item key="style-selector" label={translate('Config.Style')}>
                        <Select value={selectedStyle} onChange={onStyleChange} options={styleOptions} />
                    </Form.Item>
                    <Divider />
                    {configFields.map((field, index) => (
                        <React.Fragment key={`config-field-${field.label}`}>
                            {index > 0 && <Divider />}
                            <Form.Item label={
                                <>
                                    {translate(field.label)}
                                    {field.description && (
                                        <span style={{
                                            marginLeft: 8,
                                            fontSize: '12px',
                                            color: 'rgba(0, 0, 0, 0.45)',
                                            fontWeight: 'normal'
                                        }}>
                                            ({translate(field.description)})
                                        </span>
                                    )}
                                </>
                            }>
                                <RecursiveConfigDrawer
                                    field={field}
                                    styleConfig={styleConfig}
                                    onConfigChange={onConfigChange}
                                    translate={translate}
                                    uploadImagesMutation={uploadImagesMutation}
                                    merchantId={merchantId!}
                                    abortSignal={abortSignal}
                                    level={1}
                                    generateHelper={generateHelper}
                                    itemSearchSubType={itemSearchSubType}
                                    setItemSearchSubType={setItemSearchSubType}
                                    navBarOptions={navBarOptions}
                                    setNavBarOptions={setNavBarOptions}
                                />
                            </Form.Item>
                        </React.Fragment>
                    ))}
                </Form>
                <Divider />
                <Flex justify="space-between">
                    <Button shape="round" type="default" onClick={handleDefault}>
                        {translate('Use Default')}
                    </Button>
                    <Button
                        shape="round"
                        type="primary"
                        loading={alterPortalStyleApi.isLoading}
                        onClick={handleSave}
                    >
                        {translate('Save')}
                    </Button>
                </Flex>
            </Drawer >
        </>
    );
};

interface RecursiveConfigDrawerProps {
    field: IConfigField;
    styleConfig: Record<string, string>;
    onConfigChange: (key: string, value: string) => void;
    translate: (key: string) => string;
    uploadImagesMutation: UseMutationResult<string[], Error, { files: File[]; fieldKey: string }>;
    merchantId: BigInt;
    abortSignal?: AbortSignal;
    level: number;
    generateHelper: GenereateHelper;
    itemSearchSubType?: ItemSearchSubType;
    setItemSearchSubType: (value: ItemSearchSubType) => void;
    navBarOptions: INavBarOption[];
    setNavBarOptions: React.Dispatch<React.SetStateAction<INavBarOption[]>>;
}

const RecursiveConfigDrawer: React.FC<RecursiveConfigDrawerProps> = ({
    field,
    styleConfig,
    onConfigChange,
    translate,
    uploadImagesMutation,
    merchantId,
    abortSignal,
    level,
    generateHelper,
    itemSearchSubType,
    setItemSearchSubType,
    navBarOptions,
    setNavBarOptions,
}) => {
    const [form] = Form.useForm();
    const [childDrawerVisible, setChildDrawerVisible] = useState(false);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [editingOptionId, setEditingOptionId] = useState<BigInt | null>(null);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editingOption, setEditingOption] = useState<INavBarOption | null>(null);
    const [, forceUpdate] = useState({});

    const { data: navBarOptionItemSearchModel, isLoading: isNavBarOptionItemSearchModelLoading } = useQuery(
        ['navBarOptionItemSearchModel', merchantId],
        () => {
            return useGetNavBarOptionItemSearchModelApi(abortSignal);
        },
        { enabled: !!merchantId }
    );

    useEffect(() => {
        if (field.type === ConfigFieldType.NavBarOption) {
            setNavBarOptions(navBarOptions);
            const allKeys = getAllNodeKeys(navBarOptions);
            setExpandedKeys(allKeys);
        }
    }, [field, navBarOptions]);

    const addNavBarOption = (parentOption?: INavBarOption): void => {
        const newOption: INavBarOption = {
            id: generateHelper.getSnowflakeIdBigInt(),
            merchantId: merchantId,
            name: translate('New Option'),
            type: NavBarClickType.RedirectPage,
            path: '/',
            isDeleted: false,
            options: [],
        };

        if (parentOption) {
            const updatedOptions = updateOption(navBarOptions, parentOption.id, (option) => {
                const newOptions = option.options ? [...option.options, newOption] : [newOption];
                return { ...option, options: newOptions };
            });
            setNavBarOptions(updatedOptions);
            onConfigChange(field.key, JSONBig.stringify(updatedOptions));
        } else {
            const updatedOptions = [...navBarOptions, newOption];
            setNavBarOptions(updatedOptions);
            onConfigChange(field.key, JSONBig.stringify(updatedOptions));
        }
    };

    const updateOption = (
        options: INavBarOption[],
        targetId: BigInt,
        updater: (option: INavBarOption) => INavBarOption
    ): INavBarOption[] => {
        return options.map((option) => {
            if (option.id.toString() === targetId.toString()) {
                return updater(option);
            } else if (option.options && option.options.length > 0) {
                return { ...option, options: updateOption(option.options, targetId, updater) };
            } else {
                return option;
            }
        });
    };

    const updateNavBarOption = (updatedOption: INavBarOption) => {
        const updatedOptions = updateOption(navBarOptions, updatedOption.id, () => updatedOption);
        setNavBarOptions(updatedOptions);
        onConfigChange(field.key, JSONBig.stringify(updatedOptions));
    };

    const removeNavBarOption = (optionId: BigInt) => {
        Modal.confirm({
            title: translate('Confirm'),
            content: `${translate('Are you sure you want to delete this option')}?`,
            onOk: () => {
                const removeOption = (options: INavBarOption[], targetId: BigInt): INavBarOption[] => {
                    return options
                        .filter((option) => option.id.toString() !== targetId.toString())
                        .map((option) => ({
                            ...option,
                            options: option.options ? removeOption(option.options, targetId) : [],
                        }));
                };

                const updatedOptions = removeOption(navBarOptions, optionId);
                setNavBarOptions(updatedOptions);
                onConfigChange(field.key, JSONBig.stringify(updatedOptions));
            },
        });
    };

    const openEditModal = (option: INavBarOption) => {
        setEditingOption(option);
        setEditModalVisible(true);
        form.setFieldsValue({
            name: option.name,
            type: option.type,
            redirectPage: option.path,
            category: option.categoryIds,
            item: option.itemIds,
            tag: option.itemTagIds,
            detailItem: option.type === NavBarClickType.RedirectItemDetailPage ? option.itemIds?.[0] : undefined,
        });
    };

    const handleEditOption = (updatedOption: INavBarOption) => {
        updateNavBarOption(updatedOption);
        setEditModalVisible(false);
        setEditingOption(null);
    };

    const handleEditModalOk = () => {
        if (editingOption) {
            form.validateFields().then(values => {
                const updatedOption = {
                    ...editingOption,
                    name: values.name,
                    type: values.type,
                    path: values.type === NavBarClickType.RedirectPage ? values.redirectPage : undefined,
                    categoryIds: values.type === NavBarClickType.ItemSearch ? values.category : undefined,
                    itemIds: values.type === NavBarClickType.ItemSearch ? values.item :
                        values.type === NavBarClickType.RedirectItemDetailPage ? [values.detailItem] : undefined,
                    itemTagIds: values.type === NavBarClickType.ItemSearch ? values.tag : undefined,
                };
                handleEditOption(updatedOption);
            });
        }
    };

    const handleEditModalCancel = () => {
        setEditingOption(null);
    };

    const handleTypeChange = (value: NavBarClickType) => {
        if (editingOption) {
            const updatedOption = { ...editingOption, type: value };
            setEditingOption(updatedOption);
            // 強制組件重新渲染
            forceUpdate({});
        }
    };

    const renderNavBarOptionNode = (option: INavBarOption, level: number) => {
        const isEditing = option.id.toString() === (editingOptionId ? editingOptionId.toString() : '');
        const index = option.name.toLowerCase().indexOf(searchValue.toLowerCase());
        const beforeStr = option.name.substring(0, index);
        const matchedStr = option.name.substring(index, index + searchValue.length);
        const afterStr = option.name.substring(index + searchValue.length);

        const nameTitle =
            index > -1 ? (
                <span>
                    {beforeStr}
                    <span style={{ color: 'red' }}>{matchedStr}</span>
                    {afterStr}
                </span>
            ) : (
                <span>{option.name}</span>
            );

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    {isEditing ? (
                        <Input
                            value={option.name}
                            onChange={(e) => updateNavBarOption({ ...option, name: e.target.value })}
                            onBlur={() => setEditingOptionId(null)}
                            onPressEnter={() => setEditingOptionId(null)}
                            autoFocus
                        />
                    ) : (
                        <span onDoubleClick={() => setEditingOptionId(option.id)}>{nameTitle}</span>
                    )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}>
                    <EditOutlined
                        style={{ color: 'blue', cursor: 'pointer', marginRight: 8 }}
                        onClick={() => openEditModal(option)}
                    />
                    <CloseCircleOutlined
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => removeNavBarOption(option.id)}
                    />
                </div>
            </div>
        );
    };

    const navBarOptionsToTreeData = (
        options: INavBarOption[],
        searchValue: string,
        level: number = 1
    ): any[] => {
        const nodes = options
            .map((option) => {
                const index = option.name.toLowerCase().indexOf(searchValue.toLowerCase());
                const hasSearchMatch = index > -1;
                const children = option.options
                    ? navBarOptionsToTreeData(option.options, searchValue, level + 1)
                    : [];

                if (hasSearchMatch || children.length > 0) {
                    return {
                        key: option.id.toString(),
                        title: renderNavBarOptionNode(option, level),
                        children: [
                            ...children,
                            // 在每個子節點列表的末尾添加"Add Sub Option"
                            {
                                key: `add_sub_option_${option.id}`,
                                title: (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span
                                            style={{ color: 'green', cursor: 'pointer' }}
                                            onClick={() => addNavBarOption(option)}
                                        >
                                            <PlusOutlined /> {translate('Add')}
                                        </span>
                                    </div>
                                ),
                            },
                        ],
                    };
                } else {
                    return null;
                }
            })
            .filter((item) => item !== null);

        if (level === 1) {
            nodes.push({
                key: 'add_root_option',
                title: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                            style={{ color: 'green', cursor: 'pointer' }}
                            onClick={() => addNavBarOption()}
                        >
                            <PlusOutlined /> {translate('Add')}
                        </span>
                    </div>
                ),
                children: [],
            });
        }

        return nodes;
    };

    const [dataList, setDataList] = useState<{ key: string; name: string }[]>([]);

    const generateList = (options: INavBarOption[]) => {
        const list: { key: string; name: string }[] = [];
        const traverse = (opts: INavBarOption[]) => {
            opts.forEach((option) => {
                const key = option.id.toString();
                list.push({ key, name: option.name });
                if (option.options && option.options.length > 0) {
                    traverse(option.options);
                }
            });
        };
        traverse(options);
        setDataList(list);
    };

    useEffect(() => {
        generateList(navBarOptions);
    }, [navBarOptions]);

    const getParentKey = (key: string, tree: INavBarOption[]): string | undefined => {
        let parentKey: string | undefined;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.options) {
                if (node.options.some((item) => item.id.toString() === key)) {
                    parentKey = node.id.toString();
                } else if (getParentKey(key, node.options)) {
                    parentKey = getParentKey(key, node.options);
                }
            }
        }
        return parentKey;
    };

    const getAllNodeKeys = (options: INavBarOption[]): string[] => {
        let keys: string[] = [];
        options.forEach((option) => {
            keys.push(option.id.toString());
            if (option.options && option.options.length > 0) {
                keys = keys.concat(getAllNodeKeys(option.options));
            }
        });
        return keys;
    };

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchValue(value);
        const expandedKeys = dataList
            .map((item) => {
                if (item.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    return getParentKey(item.key, navBarOptions);
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i) as string[];
        setExpandedKeys(expandedKeys);
        setAutoExpandParent(true);
    };

    const renderConfigField = (field: IConfigField) => {
        switch (field.type) {
            case ConfigFieldType.SubMenu:
                return (
                    <>
                        <Button onClick={() => setChildDrawerVisible(true)}>
                            {translate(field.label)}
                        </Button>
                        <Drawer
                            title={translate(field.label)}
                            placement="right"
                            onClose={() => setChildDrawerVisible(false)}
                            open={childDrawerVisible}
                        >
                            <Form
                                form={form}
                                layout="vertical"
                            >
                                {field.configFields?.map((childField) => (
                                    <Form.Item
                                        key={`config-field-${childField.label}`}
                                        label={translate(childField.label)}
                                    >
                                        <RecursiveConfigDrawer
                                            field={childField}
                                            styleConfig={styleConfig}
                                            onConfigChange={onConfigChange}
                                            translate={translate}
                                            uploadImagesMutation={uploadImagesMutation}
                                            merchantId={merchantId}
                                            abortSignal={abortSignal}
                                            level={level + 1}
                                            generateHelper={generateHelper}
                                            itemSearchSubType={itemSearchSubType}
                                            setItemSearchSubType={setItemSearchSubType}
                                            navBarOptions={navBarOptions}
                                            setNavBarOptions={setNavBarOptions}
                                        />
                                    </Form.Item>
                                ))}
                            </Form>
                        </Drawer>
                    </>
                );
            case ConfigFieldType.NavBarOption:
                return (
                    <>
                        <Input
                            placeholder={translate('Search')}
                            onChange={onSearchChange}
                            style={{ marginBottom: 8 }}
                        />
                        <Tree
                            showLine={{ showLeafIcon: false }}
                            expandedKeys={expandedKeys}
                            onExpand={(keys) => {
                                setExpandedKeys(keys as string[]);
                                setAutoExpandParent(false);
                            }}
                            treeData={navBarOptionsToTreeData(navBarOptions, searchValue)}
                            autoExpandParent={autoExpandParent}
                        />
                    </>
                );
            case ConfigFieldType.Color:
                return (
                    <Input
                        type="color"
                        value={styleConfig[field.key] || field.defaultValue || '#000000'}
                        onChange={(e) => onConfigChange(field.key, e.target.value)}
                    />
                );
            case ConfigFieldType.Select:
                return (
                    <Select
                        value={styleConfig[field.key] || field.defaultValue || field.options?.[0]?.value || ''}
                        onChange={(value) => onConfigChange(field.key, value)}
                        options={field.options?.map((option) => ({
                            label: translate(option.label),
                            value: option.value,
                        }))}
                    />
                );
            case ConfigFieldType.Number:
                return (
                    <InputNumber
                        value={Number(styleConfig[field.key]) || Number(field.defaultValue) || 0}
                        onChange={(value) => onConfigChange(field.key, value?.toString() || '')}
                        min={field.min}
                        max={field.max}
                    />
                );
            case ConfigFieldType.Input:
                return (
                    <Input
                        value={styleConfig[field.key] || field.defaultValue || ''}
                        onChange={(e) => onConfigChange(field.key, e.target.value)}
                    />
                );
            case ConfigFieldType.Upload:
                const fileList = styleConfig[field.key] ? JSON.parse(styleConfig[field.key]) : [];
                return (
                    <Upload
                        fileList={fileList.map((url: string) => ({
                            uid: url,
                            name: url.split('/').pop(),
                            status: 'done',
                            url: url,
                        }))}
                        accept={field.acceptTypes ? field.acceptTypes.join(',') : 'image/*'}
                        showUploadList={true}
                        maxCount={field.maxUploadCount || 5}
                        multiple={field.maxUploadCount ? field.maxUploadCount > 1 : true}
                        onRemove={(file) => {
                            const newFileList = fileList.filter((item: string) => item !== file.url);
                            onConfigChange(field.key, JSON.stringify(newFileList));
                        }}
                        beforeUpload={(file, fileList) => {
                            uploadImagesMutation.mutate({ files: fileList, fieldKey: field.key });
                            return false;
                        }}
                    >
                        <Button icon={<UploadOutlined />} loading={uploadImagesMutation.isLoading}>
                            {fileList.length > 0 ? translate('Add More') : translate('Upload')}
                        </Button>
                    </Upload>
                );
            case ConfigFieldType.Pages:
                return (
                    <Select
                        value={styleConfig[field.key] || field.defaultValue || ''}
                        onChange={(value) => onConfigChange(field.key, value)}
                        options={navBarOptionItemSearchModel?.result?.categories.map((page) => ({
                            label: typeof page.name === 'string' ? translate(page.name) : '',
                            value: page.id?.toString() || '',
                        }))}
                        loading={isNavBarOptionItemSearchModelLoading}
                    />
                );
            case ConfigFieldType.ItemTags:
                return (
                    <Select
                        value={styleConfig[field.key] || field.defaultValue || ''}
                        onChange={(value) => onConfigChange(field.key, value)}
                        options={navBarOptionItemSearchModel?.result?.itemTags.map((tag) => ({
                            label: typeof tag.name === 'string' ? translate(tag.name) : '',
                            value: tag.id?.toString() || '',
                        }))}
                        loading={isNavBarOptionItemSearchModelLoading}
                    />
                );
            case ConfigFieldType.Categories:
                return (
                    <Select
                        value={styleConfig[field.key] || field.defaultValue || ''}
                        onChange={(value) => onConfigChange(field.key, value)}
                        options={navBarOptionItemSearchModel?.result?.categories.map((category) => ({
                            label: typeof category.name === 'string' ? translate(category.name) : '',
                            value: category.id?.toString() || '',
                        }))}
                        loading={isNavBarOptionItemSearchModelLoading}
                    />
                );
            case ConfigFieldType.Items:
                return (
                    <Select
                        value={styleConfig[field.key] || field.defaultValue || ''}
                        onChange={(value) => onConfigChange(field.key, value)}
                        options={navBarOptionItemSearchModel?.result?.items?.map((item) => ({
                            label: typeof item.name === 'string' ? translate(item.name) : '',
                            value: item.id?.toString() || '',
                        }))}
                        loading={isNavBarOptionItemSearchModelLoading}
                    />
                );
            default:
                return null;
        }
    };

    const renderItemSearchSubType = (itemSearchSubType: ItemSearchSubType | undefined) => {
        switch (itemSearchSubType) {
            case ItemSearchSubType.Category:
                return <Form.Item name={'category'} label={translate('Category')}>
                    <Select mode='multiple'>
                        {navBarOptionItemSearchModel?.result?.categories?.map((category) => (
                            <Select.Option key={category.id.toString()} value={category.id.toString()}>
                                {category.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            case ItemSearchSubType.Item:
                return <Form.Item name={'item'} label={translate('Item')}>
                    <Select mode='multiple'>
                        {navBarOptionItemSearchModel?.result?.items?.map((item) => (
                            <Select.Option key={item.id.toString()} value={item.id.toString()}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            case ItemSearchSubType.Tag:
                return <Form.Item name={'tag'} label={translate('Tag')}>
                    <Select mode='multiple'>
                        {navBarOptionItemSearchModel?.result?.itemTags?.map((itemTag) => (
                            <Select.Option key={itemTag.id.toString()} value={itemTag.id.toString()}>
                                {itemTag.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
        }
    }

    useEffect(() => {
        if (editingOption && editModalVisible) {
            console.log('editingOption', editingOption);
            form.setFieldsValue({
                name: editingOption.name,
                type: editingOption.type,
                redirectPage: editingOption.path,
                itemSearchSubType: editingOption.itemSearchSubType,
                category: editingOption.categoryIds,
                item: editingOption.itemIds,
                tag: editingOption.itemTagIds,
                detailItem: editingOption.type === NavBarClickType.RedirectItemDetailPage ? editingOption.itemIds?.[0] : undefined,
            });
        }
    }, [editingOption, editModalVisible]);

    const handleFormValuesChange = (changedValues: any, allValues: any) => {
        if (editingOption) {
            setEditingOption(prevOption => ({
                ...prevOption,
                ...allValues,
                path: allValues.type === NavBarClickType.RedirectPage ? allValues.redirectPage : undefined,
                categoryIds: allValues.type === NavBarClickType.ItemSearch ? allValues.category : undefined,
                itemIds: allValues.type === NavBarClickType.ItemSearch ? allValues.item :
                    allValues.type === NavBarClickType.RedirectItemDetailPage ? [allValues.detailItem] : undefined,
                itemTagIds: allValues.type === NavBarClickType.ItemSearch ? allValues.tag : undefined,
            }));
        }
    };

    const handleModalClose = () => {
        setEditModalVisible(false);
        setEditingOption(null);
        form.resetFields();
    };

    return (
        <>
            {renderConfigField(field)}
            <Modal
                visible={editModalVisible}
                title={translate('Edit Option')}
                onOk={handleEditModalOk}
                onCancel={handleModalClose}
            >
                {editingOption && (
                    <Form
                        form={form}
                        layout="vertical"
                        onValuesChange={handleFormValuesChange}
                        initialValues={{
                            name: editingOption.name,
                            type: editingOption.type,
                            redirectPage: editingOption.path,
                            category: editingOption.categoryIds,
                            item: editingOption.itemIds,
                            tag: editingOption.itemTagIds,
                            detailItem: editingOption.type === NavBarClickType.RedirectItemDetailPage ? editingOption.itemIds?.[0] : undefined,
                        }}
                    >
                        <Form.Item name="name" label={translate('DisplayName')}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="type" label={translate('Click type')}>
                            <Select style={{ width: '100%' }}>
                                {Object.values(NavBarClickType).map((type) => (
                                    <Select.Option key={type} value={type}>
                                        {translate(type)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {form.getFieldValue('type') === NavBarClickType.RedirectPage && (
                            <Form.Item name="redirectPage" label={translate('Redirect page')}>
                                <Select>
                                    {navBarOptionItemSearchModel?.result?.pageViewModels?.map((page, index) => (
                                        <Select.Option key={index.toString()} value={page.path.toString()}>
                                            {page.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                        {form.getFieldValue('type') === NavBarClickType.ItemSearch && (
                            <>
                                <Form.Item name="itemSearchSubType" label={translate('SubType')}>
                                    <Select onChange={(value) => setItemSearchSubType(value)}>
                                        {Object.values(ItemSearchSubType).map((type) => (
                                            <Select.Option key={type} value={type}>
                                                {translate(type)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {renderItemSearchSubType(form.getFieldValue('itemSearchSubType'))}
                            </>
                        )}
                        {form.getFieldValue('type') === NavBarClickType.RedirectItemDetailPage && (
                            <Form.Item name="detailItem" label={translate('Item')}>
                                <Select>
                                    {navBarOptionItemSearchModel?.result?.items?.map((item) => (
                                        <Select.Option key={item.id.toString()} value={item.id.toString()}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    </Form>
                )}
            </Modal>
        </>
    );
};

export default StyleConfigSelector;
