import { IAlterMerchantThirdPartyInvoiceSettingRequest } from "@/interfaces/Requests/Requests";
import { IBackOfficeMerchantThirdPartyInvoiceSettingViewModel } from "@/interfaces/Responses/Responses";
import { useAlterMerchantThirdPartyInvoiceSettingApi, useGetBackOfficeMerchantThirdPartyInvoiceSettingViewModelsApi } from "@/lib/api/thirdPartyInvoices";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Switch, Table } from "antd";
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useMutation } from "react-query";
import { ThirdPartyPageContext } from "../../Contexts/ThirdPartyPageContext";
import AddInvoice, { IAddInvoiceRef } from "./AddInvoice";
import EditInvoice, { IEditInvoiceRef } from "./EditInvoice";

export interface IInvoiceListProps {
}

export interface IInvoiceSettingListRef {
    onRefresh: () => void;
}

const InvoiceList = React.forwardRef((props: IInvoiceListProps, ref: React.ForwardedRef<IInvoiceSettingListRef | undefined>) => {
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const { abortController } = useContext(ThirdPartyPageContext);
    const addRef = useRef<IAddInvoiceRef>();
    const editRef = useRef<IEditInvoiceRef>();
    const [merchantThirdPartySettings, setMerchantThirdPartySettings] = useState<IBackOfficeMerchantThirdPartyInvoiceSettingViewModel[]>([]);

    const { mutate, isLoading } = useMutation(async () => await useGetBackOfficeMerchantThirdPartyInvoiceSettingViewModelsApi(abortController.current.signal), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setMerchantThirdPartySettings(response.result || []);
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const { mutate: alterMutate, isLoading: alterMutateIsLoading } = useMutation(async (request: IAlterMerchantThirdPartyInvoiceSettingRequest) => await useAlterMerchantThirdPartyInvoiceSettingApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                refresh();
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const [selectedEditSetting, setSelectedEditSetting] = useState<IBackOfficeMerchantThirdPartyInvoiceSettingViewModel | undefined>(undefined);
    const [openAddSetting, setOpenAddSetting] = useState(false);

    const handleToggleDisable = (record: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel, isDisable: boolean) => {
        // 檢查是否要啟用
        if (!isDisable) {
            // 取得當前記錄的國家列表
            const currentCountries = record.supportedCountry.split(',');

            // 檢查是否有其他已啟用且有相同國家的設定
            const conflictSettings = merchantThirdPartySettings.filter(setting =>
                !setting.isDisable && // 已啟用
                setting.id !== record.id && // 不是當前記錄
                setting.supportedCountry.split(',').some(country =>
                    currentCountries.includes(country)
                )
            );

            if (conflictSettings.length > 0) {
                // 找出衝突的國家
                const conflictCountries = conflictSettings
                    .flatMap(s => s.supportedCountry.split(','))
                    .filter(country => currentCountries.includes(country))
                    .map(country => translate(`Country.${country}`))
                    .join(', ');

                Modal.confirm({
                    title: translate('Warning'),
                    content: `${translate('Only one method can be enabled in the same country. Are you sure you want to switch')}?`,
                    okText: translate('Confirm'),
                    cancelText: translate('Cancel'),
                    onOk: () => {
                        alterMutate({
                            settingId: record.thirdPartySettingId,
                            setting: record.merchantSettings,
                            isDisabled: isDisable
                        });
                    }
                });
                return;
            }
        }

        // 如果是停用或沒有衝突，直接執行
        alterMutate({
            settingId: record.thirdPartySettingId,
            setting: record.merchantSettings,
            isDisabled: isDisable
        });
    };

    const columns = [
        {
            title: translate('Provider Name'),
            dataIndex: 'providerName',
            sorter: (a: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel, b: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel) => a.providerName.localeCompare(b.providerName),
            key: 'providerName',
        },
        {
            title: translate('SupportedCountry'),
            dataIndex: 'supportedCountry',
            sorter: (a: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel, b: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel) => a.supportedCountry.localeCompare(b.supportedCountry),
            render: (value: string) => value.split(',').map(country => translate(`Country.${country}`)).join(', '),
        },
        {
            title: translate('Enable'),
            key: 'isDisable',
            render: (record: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel) => (
                <Switch
                    loading={isLoading || alterMutateIsLoading}
                    checked={!record.isDisable}
                    onChange={(checked) => handleToggleDisable(record, !checked)}
                />
            ),
        },
        {
            title: translate('Operation'),
            key: 'action',
            render: (record: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel) => (
                <Button onClick={() => handleEdit(record)}>
                    {translate('Edit')}
                </Button>
            ),
        },
    ];

    const handleEdit = (record: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel) => {
        setSelectedEditSetting(record);
    };

    const refresh = () => {
        mutate();
    };

    useEffect(() => {
        if (merchantId)
            mutate();
    }, [merchantId]);

    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            mutate();
        }
    }));

    return (
        <>
            <Table columns={columns}
                pagination={{ position: ['bottomCenter'] }}
                dataSource={merchantThirdPartySettings}
                loading={isLoading}
                footer={() => {
                    return <Button
                        type="dashed"
                        onClick={() => setOpenAddSetting(true)}
                        block icon={<PlusOutlined />}>
                        {translate('Add new setting')}
                    </Button>
                }}
                rowKey={record => record.id.toString()}
                bordered />
            <Modal
                open={selectedEditSetting !== undefined}
                afterOpenChange={(open) => {
                    if (!open)
                        editRef.current?.onClose();
                    else
                        editRef.current?.onOpen(selectedEditSetting!);
                }}
                onCancel={() => setSelectedEditSetting(undefined)}
                footer={<></>}>
                <EditInvoice
                    ref={editRef}
                    onRefresh={() => refresh()}
                    onClose={() => setSelectedEditSetting(undefined)}
                />
            </Modal>
            <Modal
                open={openAddSetting}
                afterOpenChange={(open) => {
                    if (open)
                        addRef.current?.refresh();
                }}
                onCancel={() => setOpenAddSetting(false)}
                footer={<></>}>
                <AddInvoice
                    ref={addRef}
                    ownRecords={merchantThirdPartySettings}
                    onRefresh={() => refresh()}
                    onClose={() => setOpenAddSetting(false)}
                />
            </Modal>
        </>
    );
});

export default InvoiceList;
