import { OrderStatus } from "@/enums/Enums";
import { IDashboardStatisticsMonthlyViewModel, IDashboardStatisticsViewModel, IRecentOrdersByStatusViewModel, MerchantGoogleIntegrateSetting } from "@/interfaces/Responses/DataAnalysis";
import { IOperationResult, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import useAPI from '@/Templates/lib/customHooks/useAPI';

export const useGetMerchantGoogleIntegrateSettingApi = (signal?: AbortSignal) => useAPI<IOperationResultT<MerchantGoogleIntegrateSetting>>(`${process.env.BASE_API_URL}/api/DataAnalysis/GetMerchantGoogleIntegrateSetting`, {
    signal
});

export const useAlterMerchantGoogleIntegrateSettingApi = (request: MerchantGoogleIntegrateSetting) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/DataAnalysis/AlterMerchantGoogleIntegrateSetting`, {
    method: 'POST',
    body: request,
});

export const useGetRecentOrdersByStatusApi = (status: OrderStatus = OrderStatus.Done, days: number = 7, signal?: AbortSignal) => useAPI<IOperationResultT<IRecentOrdersByStatusViewModel[]>>(`${process.env.BASE_API_URL}/api/DataAnalysis/GetRecentOrdersByStatus?status=${status}&days=${days}`, {
    signal
});

// 獲取儀表板統計資料 API
export const useGetDashboardStatisticsApi = (signal?: AbortSignal) => useAPI<IOperationResultT<IDashboardStatisticsViewModel>>(`${process.env.BASE_API_URL}/api/DataAnalysis/GetDashboardStatistics`, {
    signal
});

// 獲取儀表板每月統計資料 API
export const useGetDashboardMonthlyStatisticsApi = (signal?: AbortSignal) => useAPI<IOperationResultT<IDashboardStatisticsMonthlyViewModel>>(`${process.env.BASE_API_URL}/api/DataAnalysis/GetDashboardMonthlyStatistics`, {
    signal
});