import { CurrencyCode } from "@/enums/CurrecnyCode";
import { IItemSpecDto } from "@/interfaces/Responses/Responses";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { ItemContext } from "@/lib/contexts/ItemContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { DeviceType, LogisticOrderLimitType } from "@/Templates/enums/templateEnums";
import { DeleteOutlined, MenuOutlined, PlusOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Collapse, Form, Input, InputNumber, Popconfirm, Popover, Select, Space, Switch, Table, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

const { TextArea } = Input;

export interface SpecsFormProps {
    previewOnly: boolean;
    onIsAllValid: (isAllValid: boolean) => void;
}

// Row 組件定義
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const Row: React.FC<Readonly<RowProps>> = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        cursor: 'move',
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const SpecsForm: React.FC<SpecsFormProps> = ({ previewOnly, onIsAllValid }) => {
    const [form] = Form.useForm();
    const [unfilledSpecs, setUnfilledSpecs] = useState<number[]>([]);
    const { deviceType, messageApi } = useContext(GlobalContext);
    const { itemSpecs, setItemSpecs } = useContext(ItemContext);
    const { translate, i18nLanguage } = useContext(TranslationContext);

    const [quickSpecs, setQuickSpecs] = useState([
        { key: Date.now(), name: '', options: [''] },
        { key: Date.now() + 1, name: '', options: [''] }
    ]);
    const [editingCell, setEditingCell] = useState<string>();

    const getSystemManageTooltip = () => {
        return i18nLanguage === 'zh_TW'
            ? "由系統協助管理庫存"
            : "Inventory management assisted by the system";
    };

    const getQuickSpecTooltip = () => {
        return i18nLanguage === 'zh_TW'
            ? "快速產生規格可以幫助你快速建立多個相關的產品規格。例如，你可以設「顏色」為一個選項，然後添加「紅色」、「藍色」、「綠色」作為子選項。"
            : "Quick spec generation helps you quickly create multiple related product specifications. For example, you can set 'Color' as an option and add 'Red', 'Blue', 'Green' as sub-options.";
    };

    const getLogisticOrderLimitTooltip = () => {
        return i18nLanguage === 'zh_TW'
            ? "該商品的出貨物流限制，舉例若為冷凍出貨則僅能選擇冷凍出貨物流，以及會限制買家購物車僅能相限制的商品選項一起成立訂單"
            : "This is the shipping logistics restriction for the item. For example, if it's frozen shipping, only frozen shipping logistics can be selected, and it will limit the buyer's shopping cart to only include items with the same restriction in one order.";
    };

    const generateRandomBigInt = () => BigInt(Math.floor(Math.random() * Number.MAX_SAFE_INTEGER));

    // 抽離共用的表單驗證邏輯
    const validateFormFields = async () => {
        try {
            await form.validateFields();
            setUnfilledSpecs([]);
            onIsAllValid(true);
            return true;
        } catch (e: any) {
            const errorIndexes: number[] = [];
            if (e.errorFields) {
                e.errorFields.forEach((field: any) => {
                    const index = parseInt(field.name[1]);
                    if (!isNaN(index) && !errorIndexes.includes(index)) {
                        errorIndexes.push(index);
                    }
                });
            }
            setUnfilledSpecs(errorIndexes);
            onIsAllValid(false);
            return false;
        }
    };

    // 延遲驗證的包裝函數
    const delayedValidation = () => {
        setTimeout(validateFormFields, 0);
    };

    const handleAddNewSpec = async () => {
        const newSpec: IItemSpecDto = {
            id: generateRandomBigInt(),
            name: '',
            currency: CurrencyCode.TWD,
            sellPrice: 0,
            fixedPrice: 0,
            isManageStockAmount: false,
            stockAmount: 0,
            length: 0,
            width: 0,
            height: 0,
            weight: 0,
            customizeItemNo: '',
            customizeManufacturerNo: '',
            note: "",
            createdDate: "",
            alterDate: "",
            isDeleted: false,
            logisticOrderLimitType: LogisticOrderLimitType.None,
            sortIndex: itemSpecs.length,
        };

        setItemSpecs([...itemSpecs, newSpec]);
        delayedValidation();
    };

    const handleDeleteQuickSpec = (key: number) => {
        setQuickSpecs(prevSpecs => prevSpecs.filter(spec => spec.key !== key));
    };

    const handleDeleteItemSpec = (id: BigInt) => {
        if (itemSpecs.length <= 1) {
            messageApi.error(translate("At least one spec is required"));
            return;
        }
        setItemSpecs(prevSpecs => prevSpecs.filter(spec => spec.id !== id));
    };

    const handleFormValuesChange = async (changedValues: any, allValues: { specs: IItemSpecDto[] }) => {
        const newSpecs = [...itemSpecs];

        if (changedValues.specs) {
            changedValues.specs.forEach((changedSpec: any, index: number) => {
                if (changedSpec) {
                    newSpecs[index] = {
                        ...newSpecs[index],
                        ...changedSpec,
                    };
                }
            });
        }

        setItemSpecs(newSpecs);
        delayedValidation();
    };

    const handleQuickSpecCellEdit = (value, record, dataIndex, optionIndex = null) => {
        const newData = [...quickSpecs];
        const index = newData.findIndex((item) => record.key === item.key);

        if (index > -1) {
            const item = newData[index];
            if (dataIndex === 'options') {
                item[dataIndex][optionIndex] = value;
            } else {
                item[dataIndex] = value;
            }
            setQuickSpecs(newData);
        }
        setEditingCell('');
    };

    const addQuickSpec = () => {
        const newSpec = {
            key: Date.now(),
            name: '',
            options: [''],
        };
        setQuickSpecs([...quickSpecs, newSpec]);
    };

    const addQuickSpecOption = (record) => {
        const newData = [...quickSpecs];
        const index = newData.findIndex((item) => record.key === item.key);
        if (index > -1 && newData[index].options.length < 5) {
            newData[index].options.push('');
            setQuickSpecs(newData);
        }
    };

    const generateCombinations = (specs: { name: string; options: string[] }[]) => {
        const combine = (arr: any[][]) => {
            if (arr.length === 0) return [[]];
            const restCombinations = combine(arr.slice(1));
            return arr[0].flatMap(d => restCombinations.map(c => [d, ...c]));
        };
        const optionsArray = specs.map(spec => spec.options.filter(option => option.trim() !== '').map(option => `${spec.name}: ${option}`));
        return combine(optionsArray);
    };

    const handleGenerateSpecs = () => {
        const combinations = generateCombinations(quickSpecs);
        const newSpecs: IItemSpecDto[] = combinations
            .map(combination => {
                const specName = combination.join(' - ');
                const existingSpec = itemSpecs.find(spec => spec.name === specName);
                if (existingSpec) return null;

                return {
                    id: generateRandomBigInt(),
                    name: specName,
                    currency: CurrencyCode.TWD,
                    sellPrice: 0,
                    fixedPrice: 0,
                    isManageStockAmount: false,
                    stockAmount: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    weight: 0,
                    customizeItemNo: '',
                    customizeManufacturerNo: '',
                    note: "",
                    createdDate: "",
                    alterDate: "",
                    isDeleted: false,
                    logisticOrderLimitType: LogisticOrderLimitType.None,
                };
            })
            .filter(spec => spec !== null);

        setItemSpecs([...itemSpecs, ...newSpecs]);
        delayedValidation();
    };

    // 初始化時載入規格並驗證
    useEffect(() => {
        form.setFieldsValue({ specs: itemSpecs });
        delayedValidation();
    }, [itemSpecs]);

    const quickSpecsColumns = [
        {
            title: '',
            key: 'delete',
            width: 50,
            render: (_, record) => (
                <Popconfirm
                    title={translate("Are you sure to delete this spec?")}
                    onConfirm={() => handleDeleteQuickSpec(record.key)}
                    okText={translate("Yes")}
                    cancelText={translate("No")}
                >
                    <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                </Popconfirm>
            ),
        },
        {
            title: (
                <span>
                    <Popover
                        trigger="hover"
                        content={getQuickSpecTooltip()}
                        overlayStyle={{ maxWidth: deviceType === DeviceType.Mobile ? '300px' : '400px' }}
                    >
                        <QuestionCircleTwoTone style={{ marginRight: 5 }} />
                    </Popover>
                    {translate('Options')}
                </span>
            ),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Input
                    value={text}
                    onChange={(e) => handleQuickSpecCellEdit(e.target.value, record, 'name')}
                    onFocus={() => setEditingCell(`${record.key}-name`)}
                    autoFocus={editingCell === `${record.key}-name`}
                    placeholder={translate('e.g., Pattern')}
                />
            ),
        },
        {
            title: translate('Sub options'),
            dataIndex: 'options',
            key: 'options',
            render: (options, record) => (
                <>
                    {options.map((option, index) => (
                        <Input
                            key={index}
                            value={option}
                            style={{ width: 100, marginRight: 8 }}
                            onChange={(e) => handleQuickSpecCellEdit(e.target.value, record, 'options', index)}
                            onFocus={() => setEditingCell(`${record.key}-option-${index}`)}
                            autoFocus={editingCell === `${record.key}-option-${index}`}
                            placeholder={translate('e.g., Duck')}
                        />
                    ))}
                    {options.length < 5 && (
                        <Button
                            onClick={() => addQuickSpecOption(record)}
                            size="small"
                        >
                            {translate('Add')}
                        </Button>
                    )}
                </>
            ),
        }
    ];

    const specColumns = [
        {
            title: translate('Sort'),
            dataIndex: 'sort',
            width: 70,
            fixed: 'left' as const,
            render: (_: any, __: any, index: number) => (
                <Typography.Text>
                    {index + 1}
                    <MenuOutlined style={{ marginLeft: 8, cursor: 'grab', color: '#999' }} />
                </Typography.Text>
            ),
        },
        {
            title: translate('SpecName'),
            dataIndex: 'name',
            key: 'name',
            fixed: 'left' as const,
            width: 250,
            render: (text: string, record: IItemSpecDto, index: number) => (
                <Space>
                    {!previewOnly && (
                        <Popconfirm
                            title={translate("Are you sure to delete this spec?")}
                            onConfirm={() => handleDeleteItemSpec(record.id)}
                            okText={translate("Yes")}
                            cancelText={translate("No")}
                        >
                            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                        </Popconfirm>
                    )}
                    <Form.Item
                        name={['specs', index, 'name']}
                        rules={[{ required: true, message: translate('is required') }]}
                        style={{ margin: 0, width: '100%' }}
                    >
                        <Input disabled={previewOnly} />
                    </Form.Item>
                </Space>
            ),
            align: 'center' as 'center',
        },
        {
            title: translate('Currency'),
            dataIndex: 'currency',
            key: 'currency',
            width: 110,
            render: (text: string, record: IItemSpecDto, index: number) => (
                <Form.Item
                    name={['specs', index, 'currency']}
                    initialValue={CurrencyCode.TWD}
                    rules={[{ required: true, message: translate('is required') }]}
                    style={{ margin: 0 }}
                >
                    <Select style={{ width: '100%' }} disabled={previewOnly}>
                        {Object.entries(CurrencyCode).map(([key, value]) => (
                            <Select.Option key={key} value={key}>
                                {translate(value)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            ),
            align: 'center' as 'center',
        },
        {
            title: translate('Sell Price'),
            dataIndex: 'sellPrice',
            key: 'sellPrice',
            render: (text: string, record: IItemSpecDto, index: number) => (
                <Form.Item
                    name={['specs', index, 'sellPrice']}
                    rules={[
                        { required: true, message: translate('is required') },
                        {
                            validator: (_, value) => {
                                if (value <= 0) {
                                    return Promise.reject(translate('greater than 0'));
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                    style={{ margin: 0 }}
                >
                    <InputNumber min={0} disabled={previewOnly} style={{ width: '100%' }} />
                </Form.Item>
            ),
            align: 'center' as 'center',
        },
        {
            title: translate('Fixed Price'),
            dataIndex: 'fixedPrice',
            key: 'fixedPrice',
            render: (text: string, record: IItemSpecDto, index: number) => (
                <Form.Item
                    name={['specs', index, 'fixedPrice']}
                    rules={[
                        { required: true, message: translate('is required') },
                        {
                            validator: (_, value) => {
                                if (value <= 0) {
                                    return Promise.reject(translate('greater than 0'));
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                    style={{ margin: 0 }}
                >
                    <InputNumber min={0} disabled={previewOnly} style={{ width: '100%' }} />
                </Form.Item>
            ),
            align: 'center' as 'center',
        },
        {
            title: translate('Stock Management'),
            children: [
                {
                    title: (
                        <span>
                            <Popover content={getSystemManageTooltip()} trigger="hover">
                                <QuestionCircleTwoTone style={{ marginRight: 5 }} />
                            </Popover>
                            {translate('System Manage')}
                        </span>
                    ),
                    dataIndex: 'isManageStockAmount',
                    key: 'isManageStockAmount',
                    render: (text: boolean, record: IItemSpecDto, index: number) => (
                        <Form.Item
                            name={['specs', index, 'isManageStockAmount']}
                            valuePropName="checked"
                            style={{ margin: 0 }}
                        >
                            <Switch disabled={previewOnly} />
                        </Form.Item>
                    ),
                    align: 'center' as 'center',
                },
                {
                    title: translate('Stock Amount'),
                    dataIndex: 'stockAmount',
                    key: 'stockAmount',
                    render: (text: number, record: IItemSpecDto, index: number) => (
                        <Form.Item
                            name={['specs', index, 'stockAmount']}
                            style={{ margin: 0 }}
                        >
                            <InputNumber
                                min={0}
                                disabled={previewOnly || !record.isManageStockAmount}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    ),
                    align: 'center' as 'center',
                },
            ],
        },
        {
            title: (
                <span>
                    <Popover
                        trigger="hover"
                        content={getLogisticOrderLimitTooltip()}
                        overlayStyle={{ maxWidth: deviceType === DeviceType.Mobile ? '300px' : '400px' }}
                    >
                        <QuestionCircleTwoTone style={{ marginRight: 5 }} />
                    </Popover>
                    {translate('Logistic Order Limit')}
                </span>
            ),
            dataIndex: 'logisticOrderLimitType',
            key: 'logisticOrderLimitType',
            width: 150,
            render: (text: LogisticOrderLimitType, record: IItemSpecDto, index: number) => (
                <Form.Item
                    name={['specs', index, 'logisticOrderLimitType']}
                    style={{ margin: 0 }}
                >
                    <Select disabled={previewOnly} style={{ width: '100%' }}>
                        {Object.entries(LogisticOrderLimitType).map(([key, value]) => (
                            <Select.Option key={key} value={key}>
                                {translate(value)}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            ),
            align: 'center' as 'center'
        },
        {
            title: `${translate('Length')} (cm)`,
            dataIndex: 'length',
            key: 'length',
            render: (text: string, record: IItemSpecDto, index: number) => (
                <Form.Item
                    name={['specs', index, 'length']}
                    rules={[{ required: true, message: translate('is required') }]}
                    style={{ margin: 0 }}
                >
                    <InputNumber min={0} disabled={previewOnly} style={{ width: '100%' }} />
                </Form.Item>
            ),
            align: 'center' as 'center',
        },
        {
            title: `${translate('Width')} (cm)`,
            dataIndex: 'width',
            key: 'width',
            render: (text: string, record: IItemSpecDto, index: number) => (
                <Form.Item
                    name={['specs', index, 'width']}
                    rules={[{ required: true, message: translate('is required') }]}
                    style={{ margin: 0 }}
                >
                    <InputNumber min={0} disabled={previewOnly} style={{ width: '100%' }} />
                </Form.Item>
            ),
            align: 'center' as 'center',
        },
        {
            title: `${translate('Height')} (cm)`,
            dataIndex: 'height',
            key: 'height',
            render: (text: string, record: IItemSpecDto, index: number) => (
                <Form.Item
                    name={['specs', index, 'height']}
                    rules={[{ required: true, message: translate('is required') }]}
                    style={{ margin: 0 }}
                >
                    <InputNumber min={0} disabled={previewOnly} style={{ width: '100%' }} />
                </Form.Item>
            ),
            align: 'center' as 'center',
        },
        {
            title: `${translate('Weight')} (kg)`,
            dataIndex: 'weight',
            key: 'weight',
            render: (text: string, record: IItemSpecDto, index: number) => (
                <Form.Item
                    name={['specs', index, 'weight']}
                    rules={[{ required: true, message: translate('is required') }]}
                    style={{ margin: 0 }}
                >
                    <InputNumber min={0} disabled={previewOnly} style={{ width: '100%' }} />
                </Form.Item>
            ),
            align: 'center' as 'center',
        },
        {
            title: translate('Customize Item No'),
            dataIndex: 'customizeItemNo',
            key: 'customizeItemNo',
            render: (text: string, record: IItemSpecDto, index: number) => (
                <Form.Item
                    name={['specs', index, 'customizeItemNo']}
                    style={{ margin: 0 }}
                >
                    <Input disabled={previewOnly} />
                </Form.Item>
            ),
            align: 'center' as 'center',
        },
        {
            title: translate('Customize Manufacturer No'),
            dataIndex: 'customizeManufacturerNo',
            key: 'customizeManufacturerNo',
            render: (text: string, record: IItemSpecDto, index: number) => (
                <Form.Item
                    name={['specs', index, 'customizeManufacturerNo']}
                    style={{ margin: 0 }}
                >
                    <Input disabled={previewOnly} />
                </Form.Item>
            ),
            align: 'center' as 'center',
        },
        {
            title: translate('Note'),
            dataIndex: 'note',
            key: 'note',
            render: (text: string, record: IItemSpecDto, index: number) => (
                <Form.Item
                    name={['specs', index, 'note']}
                    style={{ margin: 0 }}
                >
                    <TextArea disabled={previewOnly} />
                </Form.Item>
            ),
            align: 'center' as 'center',
        },
    ];

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 1,
            },
        }),
    );

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setItemSpecs((prev) => {
                const activeIndex = prev.findIndex((i) => i.id.toString() === active.id);
                const overIndex = prev.findIndex((i) => i.id.toString() === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Form
                style={{ width: '90%' }}
                form={form}
                name="dynamic_spec_form"
                onValuesChange={handleFormValuesChange}
                layout="vertical"
                autoComplete="off">
                <Typography.Title level={3} style={{ textAlign: 'center' }}>
                    <Popover
                        trigger="hover"
                        content={i18nLanguage === 'zh_TW'
                            ? "規格讓您能夠定義產品的不同變體。每個規格可以有不同的價格、庫存和其他屬性。"
                            : "Specs allow you to define different variants of your product. Each spec can have different prices, inventory, and other attributes."}
                        overlayStyle={{ maxWidth: deviceType === DeviceType.Mobile ? '300px' : '400px' }}
                    >
                        <QuestionCircleTwoTone style={{ marginRight: 5 }} />
                    </Popover>
                    {translate('Specs')}
                </Typography.Title>

                <Collapse
                    bordered
                    ghost>
                    <Collapse.Panel
                        header={
                            <Typography.Title level={4}>
                                <Popover
                                    trigger="hover"
                                    content={getQuickSpecTooltip()}
                                    overlayStyle={{ maxWidth: deviceType === DeviceType.Mobile ? '300px' : '400px' }}
                                >
                                    <QuestionCircleTwoTone style={{ marginRight: 5 }} />
                                </Popover>
                                {translate('Quick generate specs')}
                            </Typography.Title>
                        }
                        key="1"
                    >
                        <Table
                            dataSource={quickSpecs}
                            columns={quickSpecsColumns}
                            pagination={false}
                            rowKey="key"
                            bordered
                        />
                        <Space style={{ width: '100%', justifyContent: 'space-between', marginTop: 10 }}>
                            <Button
                                onClick={addQuickSpec}
                                type="dashed"
                                icon={<PlusOutlined />}
                            >
                                {translate('Add') + ' ' + translate('Option')}
                            </Button>
                            <Button
                                disabled={quickSpecs.length === 0}
                                onClick={handleGenerateSpecs}
                                type="primary"
                            >
                                {translate('Generate')}
                            </Button>
                        </Space>
                    </Collapse.Panel>
                </Collapse>

                <DndContext
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={onDragEnd}
                >
                    <SortableContext
                        items={itemSpecs.map(i => i.id.toString())}
                        strategy={verticalListSortingStrategy}
                    >
                        <Table
                            components={{
                                body: { row: Row },
                            }}
                            columns={specColumns}
                            dataSource={itemSpecs}
                            rowKey={record => record.id.toString()}
                            pagination={false}
                            scroll={{ x: 1500, y: 400 }}
                            style={{ marginTop: 20 }}
                            bordered
                        />
                    </SortableContext>
                </DndContext>

                <Button
                    onClick={handleAddNewSpec}
                    type="dashed"
                    style={{ marginTop: 16, width: '100%' }}
                    icon={<PlusOutlined />}
                    disabled={previewOnly}
                >
                    {translate('New spec')}
                </Button>
            </Form>
        </div>
    );
};

export default SpecsForm;
