import { LocalStorageConstants } from '@/constants/localStorageConstant';
import { CurrencyCode } from '@/enums/CurrecnyCode';
import { IMerchantPortalOptionSettingViewModel, IPaginationResponse } from '@/interfaces/Responses/Responses';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import NavBar from '@/pages/AppPages/Components/NavBar';
import ItemCard from '@/Templates/components/ItemCard';
import ScrollToTopBtn from '@/Templates/components/ScrollToTopBtn';
import { DeviceType, MerchantCheckoutType, MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents, MerchantPortalStyleShopPages, NavBarClickType } from '@/Templates/enums/templateEnums';
import { IItemSpec, IItemViewModel, IMerchantPortalStyleSetting, INavBarOptionDto, MerchantMarqueeSettingViewModel } from '@/Templates/interfaces/templatesInterfaces';
import { ItemCardTemplateProps, ItemDetailPageTemplateProps, NavBarTemplateProps, PagesTemplateProps, TemplateProps } from '@/Templates/TemplateProps';
import * as antdIcons from '@ant-design/icons';
import * as antdProForm from '@ant-design/pro-form';
import { transform } from '@babel/standalone';
import * as fontawesome from '@fortawesome/react-fontawesome';
import * as antd from 'antd';
import * as framerMotion from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import * as reactRouterDom from 'react-router-dom';
import * as uuid from 'uuid';

declare global {
    interface Window {
        UI_Preview_Only_LocalStorageConstants: typeof LocalStorageConstants;
        UI_Preview_Only_DeviceType: typeof DeviceType;
        UI_Preview_Only_MerchantCheckoutType: typeof MerchantCheckoutType;
        UI_Preview_Only_NavBarClickType: typeof NavBarClickType;
        UI_Preview_Only_MerchantPortalStyleSettingType: typeof MerchantPortalStyleSettingType;
        UI_Preview_Only_MerchantPortalStyleShopPages: typeof MerchantPortalStyleShopPages;
        UI_Preview_Only_MerchantPortalStyleShopComponents: typeof MerchantPortalStyleShopComponents;
        UI_Preview_Only_INavBarOptionDto: INavBarOptionDto;
        UI_Preview_Only_IMerchantPortalStyleSetting: IMerchantPortalStyleSetting;
        UI_Preview_Only_IMerchantPortalOptionSettingViewModel: IMerchantPortalOptionSettingViewModel;
        UI_Preview_Only_IItemViewModel: IItemViewModel;
        UI_Preview_Only_IPaginationResponse: IPaginationResponse<any>;
        UI_Preview_Only_TemplateProps: TemplateProps;
        UI_Preview_Only_PagesTemplateProps: PagesTemplateProps;
        UI_Preview_Only_ItemDetailPageTemplateProps: ItemDetailPageTemplateProps;
        UI_Preview_Only_NavBarTemplateProps: NavBarTemplateProps;
        UI_Preview_Only_ItemCardTemplateProps: ItemCardTemplateProps;
        UI_Preview_Only_MessageApi: any;
        UI_Preview_Only_translate: (key: string, nameSpace?: string) => string;
        UI_Preview_Only_MerchantMarqueeSettingViewModel: MerchantMarqueeSettingViewModel;
    }
}

interface UIPreviewComponentProps {
    code: string;
}

// 錯誤邊界組件，用於捕獲和顯示渲染錯誤
class ErrorBoundary extends React.Component<{ children: React.ReactNode, fallback: React.ReactNode }, { hasError: boolean, error: Error | null }> {
    constructor(props: { children: React.ReactNode, fallback: React.ReactNode }) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("捕獲到未處理的錯誤:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ color: 'red', padding: '10px', border: '1px solid red' }}>
                    <h3>渲染錯誤</h3>
                    <p>{this.state.error?.message}</p>
                    <details>
                        <summary>錯誤詳情</summary>
                        <pre>{this.state.error?.stack}</pre>
                    </details>
                </div>
            );
        }
        return this.props.children;
    }
}

// UIPreviewComponent: 用於預覽和渲染動態生成的React組件
const UIPreviewComponent: React.FC<UIPreviewComponentProps> = ({ code }) => {
    // 狀態管理
    const [Component, setComponent] = useState<React.FC | null>(null); // 存儲動態創建的組件
    const [error, setError] = useState<string | null>(null); // 存儲錯誤信息
    const [propsType, setPropsType] = useState<string | null>(null); // 存儲組件的props類型
    const [componentName, setComponentName] = useState<string | null>(null); // 存儲組件名稱
    const { messageApi } = useContext(GlobalContext); // 獲取全局消息API
    const { translate } = useContext(TranslationContext); // 獲取翻譯函數
    const [rerenderActionNumber, setRerenderActionNumber] = useState(0); // 用於強制重新渲染組件
    const [key, setKey] = useState(0); // 用於強制重新渲染組件

    // 初始化全局對象，用於模擬組件運行環境
    const initializeGlobalObjects = () => {
        const window = globalThis as any;
        window.UI_Preview_Only_LocalStorageConstants = LocalStorageConstants;
        window.UI_Preview_Only_DeviceType = DeviceType;
        window.UI_Preview_Only_MerchantCheckoutType = MerchantCheckoutType;
        window.UI_Preview_Only_NavBarClickType = NavBarClickType;
        window.UI_Preview_Only_MerchantPortalStyleSettingType = MerchantPortalStyleSettingType;
        window.UI_Preview_Only_MerchantPortalStyleShopPages = MerchantPortalStyleShopPages;
        window.UI_Preview_Only_MerchantPortalStyleShopComponents = MerchantPortalStyleShopComponents;
        window.UI_Preview_Only_INavBarOptionDto = createNavBarOptionDto();
        window.UI_Preview_Only_IMerchantPortalStyleSetting = createMerchantPortalStyleSetting();
        window.UI_Preview_Only_IMerchantPortalOptionSettingViewModel = createMerchantPortalOptionSettingViewModel();
        window.UI_Preview_Only_IItemViewModel = createItemViewModel();
        window.UI_Preview_Only_IPaginationResponse = createPaginationResponse();
        window.UI_Preview_Only_TemplateProps = createTemplateProps();
        window.UI_Preview_Only_PagesTemplateProps = createPagesTemplateProps();
        window.UI_Preview_Only_ItemDetailPageTemplateProps = createItemDetailPageTemplateProps();
        window.UI_Preview_Only_NavBarTemplateProps = createNavBarTemplateProps();
        window.UI_Preview_Only_ItemCardTemplateProps = createItemCardTemplateProps();
        window.UI_Preview_Only_MessageApi = messageApi;
        window.UI_Preview_Only_translate = translate;
    };

    // 創建各種模擬數據的函數
    const createNavBarOptionDto = (): INavBarOptionDto => ({
        id: BigInt(1),
        merchantId: BigInt(1),
        name: "測試導航",
        type: NavBarClickType.None,
        extraInfo: "",
        isDeleted: false,
        orderIndex: 1,
        childrenIds: []
    });

    const createMerchantPortalStyleSetting = (): IMerchantPortalStyleSetting => ({
        id: BigInt(1),
        merchantId: BigInt(1),
        type: MerchantPortalStyleSettingType.Components,
        name: "測試樣式",
        style: 1,
        styleSetting: {}
    });

    const createItemDetailPageTemplateProps = (): ItemDetailPageTemplateProps => ({
        ...createPagesTemplateProps(),
        itemSpecs: createItemSpecsViewModel(),
        item: createItemViewModel(),
        selectedItemSpec: null,
        purchaseQuantity: 0,
        isAddToShoppingCartLoading: false,
        isAddToTempShoppingCartLoading: false,
        handleSelectChange: function (value: string): void {
            var itemSpec = window.UI_Preview_Only_ItemDetailPageTemplateProps.itemSpecs.find(spec => spec.id === BigInt(value));
            window.UI_Preview_Only_ItemDetailPageTemplateProps.selectedItemSpec = itemSpec as IItemSpec;
            setRerenderActionNumber(prev => prev + 1);
        },
        setPurchaseQuantity: function (value: number): void {
            window.UI_Preview_Only_ItemDetailPageTemplateProps.purchaseQuantity = value;
            setRerenderActionNumber(prev => prev + 1);
        },
        shoppingCartItemCount: 0,
        handleAddToShoppingCart: async function (): Promise<void> {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    });

    const createMerchantPortalOptionSettingViewModel = (): IMerchantPortalOptionSettingViewModel => ({
        merchantId: BigInt(1),
        merchantName: "測試商家",
        country: "中國",
        merchantIconPath: "",
        portalLanguage: "zh-CN",
        merchantCheckoutType: MerchantCheckoutType.Normal,
        isEnableInovice: false,
        isEnableMarquee: false,
        updatedDate: new Date().toISOString(),
        orderMinimumAmountLimit: 0,
        isCustomLandingPage: false,
        isCustomPortalPage: false,
    });

    const createItemViewModel = (): IItemViewModel => ({
        id: BigInt(1),
        name: "測試商品",
        title: "測試標題",
        briefly: "簡短描述",
        description: "詳細描述",
        createdDate: new Date().toISOString(),
        alterDate: new Date().toISOString(),
        isDelete: false,
        isOnShelf: true,
        itemConverImagePaths: ["https://cdn.junxiaotech.com/microshop-bucket/system/TestItem.jpg"],
        itemTags: [],
        minPrice: 100,
        maxPrice: 200,
        isSoldOut: false
    });

    const createPaginationResponse = (): IPaginationResponse<any> => ({
        totalCount: 1,
        totalPage: 1,
        data: [createItemViewModel()]
    });

    const createItemSpecsViewModel = (): IItemSpec[] => ([
        {
            id: BigInt(1),
            name: '測試規格1',
            customizeItemNo: 'TEST001',
            currency: CurrencyCode.TWD,
            sellPrice: 100,
            fixedPrice: 120,
            isManageStockAmount: true,
            stockAmount: 50,
            length: 10,
            width: 5,
            height: 2,
            weight: 1,
            note: '這是測試規格1的備註',
            customizeManufacturerNo: 'MAN001',
            createdDate: new Date().toISOString(),
            alterDate: new Date().toISOString(),
            isDeleted: false,
            sortIndex: 0,
        },
        {
            id: BigInt(2),
            name: '測試規格2',
            customizeItemNo: 'TEST002',
            currency: CurrencyCode.TWD,
            sellPrice: 200,
            fixedPrice: 220,
            isManageStockAmount: true,
            stockAmount: 30,
            length: 15,
            width: 7,
            height: 3,
            weight: 1.5,
            note: '這是測試規格2的備註',
            customizeManufacturerNo: 'MAN002',
            createdDate: new Date().toISOString(),
            alterDate: new Date().toISOString(),
            isDeleted: false,
            sortIndex: 1,
        },
        {
            id: BigInt(3),
            name: '測試規格3',
            customizeItemNo: 'TEST003',
            currency: 'USD',
            sellPrice: 300,
            fixedPrice: 320,
            isManageStockAmount: true,
            stockAmount: 20,
            length: 20,
            width: 10,
            height: 5,
            weight: 2,
            note: '這是測試規格3的備註',
            customizeManufacturerNo: 'MAN003',
            createdDate: new Date().toISOString(),
            alterDate: new Date().toISOString(),
            isDeleted: false,
            sortIndex: 2,
        }
    ]);

    const createTemplateProps = (): TemplateProps => ({
        deviceType: DeviceType.Desktop,
        merchantId: BigInt(1),
        config: {},
        translate: translate,
        isPreviewMode: true,
        portalOptionSettingViewModel: createMerchantPortalOptionSettingViewModel(),
        portalStyleSettings: [createMerchantPortalStyleSetting()],
        messageApi: messageApi,
        BASE_PATH: '/',
    });

    const createPagesTemplateProps = (): PagesTemplateProps => ({
        ...createTemplateProps(),
        deviceType: DeviceType.Desktop,
        items: createPaginationResponse(),
        queryState: {
            options: undefined,
            form: undefined,
            request: {},
            setRequest: () => { },
            search: () => { },
            loading: false,
            fuzzingName: "",
            categoryIds: [],
            itemTagIds: [],
            onFormValuesChange: () => { },
            onSearch: () => { },
            isLoading: false
        },
    });

    const createNavBarTemplateProps = (): NavBarTemplateProps => ({
        ...createTemplateProps(),
        BASE_PATH: "/",
        isHideNavBar: false,
        merchantPortalOptionSetting: createMerchantPortalOptionSettingViewModel(),
        navBarHeight: 60,
        deviceType: DeviceType.Desktop,
        shoppingCartItemCount: 0,
        isLogin: false,
        setNavBarHeight: () => { },
        setNavBarGap: () => { },
        setDeviceType: () => { },
        setIsLogin: () => { },
        refreshShoppingCartItemCount: () => { }
    });

    const createItemCardTemplateProps = (): ItemCardTemplateProps => ({
        ...createTemplateProps(),
        item: createItemViewModel(),
        deviceType: DeviceType.Desktop,
        isloading: false
    });

    // 轉換代碼：移除import語句，提取props類型和組件名稱
    const transformCode = (code: string): { transformedCode: string, propsType: string | null, componentName: string | null } => {
        const propsTypeMatch = code.match(/React\.FC<(\w+)>/);
        const propsType = propsTypeMatch ? propsTypeMatch[1] : null;

        // 提取默認導出的組件名稱
        const exportDefaultMatch = code.match(/export\s+default\s+(\w+)/);
        const componentName = exportDefaultMatch ? exportDefaultMatch[1] : null;

        // 移除所有 export 語句
        const codeWithoutExports = code.replace(/export\s+(const|function|class|default)\s+/g, '');

        // 移除 import 語句
        const codeWithoutImports = codeWithoutExports.replace(/^import\s+(?:(?:(?:[\w*\s{},]*)\s+from\s+)?(?:(?:".*?")|(?:'.*?'))[\s]*[;]?)/gm, '');

        const transformedCode = transform(codeWithoutImports, {
            presets: [['typescript', { allExtensions: true, isTSX: true }], 'react'],
            plugins: ['proposal-class-properties', 'proposal-object-rest-spread'],
        }).code;

        return { transformedCode, propsType, componentName };
    };

    // 創建動態組件
    const createComponent = (transformedCode: string, propsType: string | null, componentName: string | null): React.FC<any> | null => {
        if (!componentName) {
            throw new Error('未找到導出的組件名稱');
        }
        try {
            const wrappedCode = `
                const { useState, useEffect, useContext, useCallback, useMemo, useRef ,forwardRef} = React;
                const { messageApi } = window.UI_Preview_Only_MessageApi;
                ${transformedCode}
                return ${componentName} || Template;
            `;
            // 載入套件
            const ComponentFunction = new Function(
                'React',
                'antd',
                'antdProForm',
                'antdIcons',
                'framerMotion',
                'uuid',
                'reactRouterDom',
                'props',
                'ScrollToTopBtn',
                'ItemCard',
                'NavBar',
                'DeviceType',
                'MerchantCheckoutType',
                'NavBarClickType',
                'MerchantPortalStyleSettingType',
                'MerchantPortalStyleShopComponents',
                'MerchantPortalStyleShopPages',
                'IItemViewModel',
                'IMerchantPortalOptionSettingViewModel',
                'IMerchantPortalStyleSetting',
                'INavBarOptionDto',
                'IPaginationResponse',
                'ItemCardTemplateProps',
                'NavBarTemplateProps',
                'PagesTemplateProps',
                'ItemDetailPageTemplateProps',
                'TemplateProps',
                'translate',
                'fontawesome',
                wrappedCode
            );

            // 創建一個有效的 React 函數組件
            const WrappedComponent: React.FC = function (componentProps: any) {
                try {
                    const propKey = `UI_Preview_Only_${propsType}`;
                    const props = (window as any)[propKey] || {};
                    const mergedProps = { ...props, ...componentProps };
                    console.log('渲染組件時的props:', mergedProps);
                    // 加載依賴項
                    return ComponentFunction(
                        React,
                        antd,
                        antdProForm,
                        antdIcons,
                        framerMotion,
                        uuid,
                        reactRouterDom,
                        mergedProps,
                        ScrollToTopBtn,
                        ItemCard,
                        NavBar,
                        DeviceType,
                        MerchantCheckoutType,
                        NavBarClickType,
                        MerchantPortalStyleSettingType,
                        MerchantPortalStyleShopComponents,
                        MerchantPortalStyleShopPages,
                        window.UI_Preview_Only_IItemViewModel,
                        window.UI_Preview_Only_IMerchantPortalOptionSettingViewModel,
                        window.UI_Preview_Only_IMerchantPortalStyleSetting,
                        window.UI_Preview_Only_INavBarOptionDto,
                        window.UI_Preview_Only_IPaginationResponse,
                        window.UI_Preview_Only_ItemCardTemplateProps,
                        window.UI_Preview_Only_NavBarTemplateProps,
                        window.UI_Preview_Only_PagesTemplateProps,
                        window.UI_Preview_Only_ItemDetailPageTemplateProps,
                        window.UI_Preview_Only_TemplateProps,
                        window.UI_Preview_Only_translate,
                        fontawesome
                    );
                } catch (err: any) {
                    console.error('組件渲染錯誤:', err);
                    return React.createElement('div', { style: { color: 'red' } }, '組件渲染錯誤: ' + err.message);
                }
            };
            return WrappedComponent;
        } catch (err: any) {
            console.error('創建組件時出錯:', err);
            return () => React.createElement('div', { style: { color: 'red' } }, '創建組件失敗: ' + err.message);
        }
    };

    useEffect(() => {
        initializeGlobalObjects(); // 初始化全局對象
    }, []);

    // 主要的副作用：當代碼變化時，重新創建和渲染組件
    useEffect(() => {
        try {
            const { transformedCode, propsType: extractedPropsType, componentName: extractedComponentName } = transformCode(code);
            console.log('提取的組件名稱:', extractedComponentName);
            console.log('提取的 propsType:', extractedPropsType);
            setPropsType(extractedPropsType);
            setComponentName(extractedComponentName);
            const ComponentFunction = createComponent(transformedCode, extractedPropsType, extractedComponentName);
            if (ComponentFunction && typeof ComponentFunction === 'function') {
                console.log('成功創建組件函數');
                setComponent(ComponentFunction);
                setError(null);
            } else {
                throw new Error('無效的組件函數');
            }
            setKey(prevKey => prevKey + 1);
        } catch (err: any) {
            console.error('轉換代碼時出錯:', err);
            setComponent(null);
            setError(err.message);
        }
    }, [code]);

    // 渲染內容
    const renderContent = () => {
        if (error) {
            return <div style={{ color: 'red' }}>{translate('錯誤')}: {error}</div>;
        }
        if (Component && typeof Component === 'function') {
            console.log('正在嘗試渲染組件');
            const propKey = `UI_Preview_Only_${propsType}`;
            const props = (window as any)[propKey] || {};
            console.log('渲染時使用的props:', props);
            return (
                <ErrorBoundary
                    key={key}
                    fallback={<div style={{ color: 'red' }}>{translate('渲染錯誤')}</div>}
                >
                    <Component {...props} React={React} />
                </ErrorBoundary>
            );
        }
        return <div>{translate('預覽將在此處顯示...')}</div>;
    };

    return renderContent();
};

export default UIPreviewComponent;