import DomigoLogo from '@/assets/Domigo-Logo-removebg-preview.png';
import { useThirdPartyLoginApi } from "@/lib/api/thirdPartyLogin";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LineLogin from '@/lib/thirdParty/LineLogin';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import { FacebookOutlined, GoogleOutlined, LockOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Divider, Flex, Form, Image, Input, InputRef, Tabs } from "antd";
import FormItem from "antd/es/form/FormItem";
import { MD5 } from "crypto-js";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from 'react-query';
import { useNavigate } from "react-router-dom";
import { LoginTypeEnum, ThirdPartyLoginProviders } from '../../enums/Enums';
import { IBackOfficeThirdPartyLoginRequest, ILoginRequest } from '../../interfaces/Requests/Requests';
import { useMerchantLoginApi } from "../../lib/api/merchants";
import { GlobalContext } from '../../lib/contexts/GlobalContext';

const LoginPage = () => {
  const { translate } = useContext(TranslationContext);
  const { messageApi,
    setIsPageLoading,
    setIsLogin,
    setIsHideNavBar,
    removeMerchantIdAggregate,
    setIsHideAnnouncement
  } = useContext(GlobalContext);
  const emailRef = useRef<InputRef>(null);
  const [loginRequest, setLoginRequest] = useState<ILoginRequest>({
    loginType: LoginTypeEnum.Email,
    email: process.env.TEST_ACCOUNT ? process.env.TEST_ACCOUNT : "",
    hashPassword: process.env.TEST_PASSWORD ? process.env.TEST_PASSWORD : "",
    phoneNumber: process.env.TEST_PHONE_NUMBER ? process.env.TEST_PHONE_NUMBER : "",
  });
  console.log(process.env.TEST_PASSWORD);
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      var request: IBackOfficeThirdPartyLoginRequest = {
        provider: ThirdPartyLoginProviders.Google,
        accessToken: codeResponse.code
      };
      thirdPartyLoginMutate(request);
    },
    onError: errorResponse => console.log(errorResponse),
  });
  const facebookLogin = () => {
    FacebookLoginClient.login((codeResponse) => {
      if (codeResponse.status !== 'connected') {
        messageApi.error('Operation failed');
        return;
      }
      var request: IBackOfficeThirdPartyLoginRequest = {
        provider: ThirdPartyLoginProviders.Facebook,
        accessToken: codeResponse.authResponse?.accessToken!
      };
      thirdPartyLoginMutate(request);
    },
      {
        scope: 'public_profile, email',
      });
  };
  const loginResponseHandler = (data: IOperationResultT<string>) => {
    if (data?.isSuccess === true) {
      localStorage.setItem("JWTToken", data.result!); //SET JWTToken
      removeMerchantIdAggregate();
      setIsLogin(true);
      messageApi.success(translate('Operation success'));
      setIsHideNavBar(false);
      navigate(`/`, { replace: true });
    }
    else {
      messageApi.error(data?.message);
    }
  }
  const { data, mutate: memberLoginMutate, isLoading: loginMutating } = useMutation(async (request: ILoginRequest) => {
    setIsPageLoading(true);
    return await useMerchantLoginApi(request);
  },
    {
      onSuccess: (data) => {
        loginResponseHandler(data);
      },
      onSettled: () => {
        setIsPageLoading(false);
      }
    }
  );
  const { mutate: thirdPartyLoginMutate, isLoading: thirdPartyLoginMutating } = useMutation(async (reqeust: IBackOfficeThirdPartyLoginRequest) => {
    setIsPageLoading(true);
    return await useThirdPartyLoginApi(reqeust);
  },
    {
      onSuccess: (data) => {
        loginResponseHandler(data);
      },
      onSettled: () => {
        setIsPageLoading(false);
      }
    }
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const handelChange = (type: any, value: any) => {
    setLoginRequest({ ...loginRequest, [type]: value });
  };
  const token = localStorage.getItem("JWTToken");
  const inputRef = useRef<HTMLInputElement>(null);
  function handelSubmit(value: any) {
    const hashedRequest = {
      ...loginRequest,
      hashPassword: MD5(loginRequest.hashPassword).toString().toLowerCase()
    };
    memberLoginMutate(hashedRequest);
  };

  const [loginType, setLoginType] = useState<LoginTypeEnum>(LoginTypeEnum.Email);

  const handleLoginTypeChange = (type: LoginTypeEnum) => {
    setLoginType(type);
    setLoginRequest({
      ...loginRequest,
      loginType: type,
      email: '',
      phoneNumber: '',
      hashPassword: ''
    });
    form.resetFields();
  };

  useEffect(() => {
    setIsHideNavBar(true);
    setIsHideAnnouncement(true);
    FacebookLoginClient.loadSdk('en_US').then(() => {
      FacebookLoginClient.init({ appId: `${process.env.FacebookAppId}`, version: 'v16.0' });
    });
    if (token !== null) {
      console.log('token,', token);
      navigate(`/login`, { replace: true });
    }
    return () => {
      setIsHideNavBar(false);
      setIsHideAnnouncement(false);
    }
  }, []);

  return (
    <div className="flex justify-center items-center h-[100vh]">
      {(thirdPartyLoginMutating || loginMutating) &&
        <LoadingComponent>
        </LoadingComponent>
      }
      {!(thirdPartyLoginMutating || loginMutating) &&
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
          <Flex justify="center" align="middle" className="mb-6">
            <Image
              src={DomigoLogo}
              width={100}
              height={100}
              preview={false}
            />
          </Flex>

          <Tabs
            activeKey={loginType}
            onChange={(key) => handleLoginTypeChange(key as LoginTypeEnum)}
            centered
            items={[
              {
                key: LoginTypeEnum.Email,
                label: translate('Email Login'),
                children: (
                  <Form
                    form={form}
                    name="login-form"
                    initialValues={{
                      email: loginRequest.email,
                      hashPassword: loginRequest.hashPassword,
                    }}
                  >
                    <FormItem
                      name="email"
                      rules={[
                        { required: true, message: translate('is required') },
                        {
                          pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                          message: translate("Must be a valid email format"),
                        },
                      ]}
                    >
                      <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder={translate('Email')}
                        ref={emailRef}
                        value={loginRequest.email}
                        onChange={(e) => handelChange("email", e.target.value)}
                      />
                    </FormItem>

                    <FormItem
                      name="hashPassword"
                      rules={[{ required: true, message: translate('is required') }]}
                    >
                      <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder={translate('Password')}
                        value={loginRequest.hashPassword}
                        onChange={(e) => handelChange("hashPassword", e.target.value)}
                      />
                    </FormItem>

                    <FormItem>
                      <Flex justify="center">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button mr-2"
                          loading={thirdPartyLoginMutating || loginMutating}
                          onClick={(value) => handelSubmit(value)}
                        >
                          {translate("Login")}
                        </Button>
                      </Flex>
                    </FormItem>
                  </Form>
                )
              },
              {
                key: LoginTypeEnum.PhoneNumber,
                label: translate('Phone Login'),
                children: (
                  <Form
                    form={form}
                    name="phone-login-form"
                    initialValues={{
                      phoneNumber: loginRequest.phoneNumber,
                      hashPassword: loginRequest.hashPassword,
                    }}
                  >
                    <FormItem
                      name="phoneNumber"
                      rules={[
                        { required: true, message: translate('is required') },
                        {
                          pattern: /^09\d{8}$/,
                          message: translate("Must be a valid phone number format"),
                        },
                      ]}
                    >
                      <Input
                        prefix={<PhoneOutlined className="site-form-item-icon" />}
                        placeholder={translate('Phone Number')}
                        value={loginRequest.phoneNumber}
                        onChange={(e) => handelChange("phoneNumber", e.target.value)}
                      />
                    </FormItem>

                    <FormItem
                      name="hashPassword"
                      rules={[{ required: true, message: translate('is required') }]}
                    >
                      <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder={translate('Password')}
                        value={loginRequest.hashPassword}
                        onChange={(e) => handelChange("hashPassword", e.target.value)}
                      />
                    </FormItem>

                    <FormItem>
                      <Flex justify="center">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button mr-2"
                          loading={thirdPartyLoginMutating || loginMutating}
                          onClick={(value) => handelSubmit(value)}
                        >
                          {translate("Login")}
                        </Button>
                      </Flex>
                    </FormItem>
                  </Form>
                )
              }
            ]}
          />

          <Divider>{translate('Third Party') + ' ' + translate('Login')}</Divider>
          <Flex wrap="wrap" gap={16} justify="center">
            <Button
              loading={thirdPartyLoginMutating || loginMutating}
              onClick={() => googleLogin()}>
              <GoogleOutlined />Google
            </Button>
            <Button
              loading={thirdPartyLoginMutating || loginMutating}
              onClick={() => facebookLogin()}>
              <FacebookOutlined />Facebook
            </Button>
            <LineLogin
              tokenCallback={(token) => {
                var request: IBackOfficeThirdPartyLoginRequest = {
                  provider: ThirdPartyLoginProviders.Line,
                  accessToken: token,
                  extraData: 'https://backoffice.domigo-digital.com/line-login'
                };
                navigate('/login', { replace: true });
                thirdPartyLoginMutate(request);
              }}
            />
          </Flex>

          <Divider />
          <Flex wrap="wrap" gap={16} justify="center" align="center">
            {translate('New to our platform') + '?'}
            <Button
              type="link"
              onClick={() => navigate(`/register`)}
              className="ml-2"
            >
              {translate('Register now')}
            </Button>
          </Flex>
        </div>
      }
    </div >
  );
};

export default LoginPage;
