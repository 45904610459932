import { IAddMerchantThirdPartyInvoiceSettingRequest } from "@/interfaces/Requests/Requests";
import { IBackOfficeMerchantThirdPartyInvoiceSettingViewModel } from "@/interfaces/Responses/Responses";
import { IThirdPartyInvoiceSetting } from "@/interfaces/Responses/ThirdPartyInvoice";
import { useGetSupportedCountriesApi } from "@/lib/api/portals";
import { useAddMerchantThirdPartyInvoiceSettingApi, useGetThirdPartyInvoiceSettingsApi } from "@/lib/api/thirdPartyInvoices";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Col, Empty, Form, Input, Row, Select } from "antd";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { useMutation } from "react-query";
import InformationPopover from "./Popovers/InformationPopver";

export interface IAddInvoiceProps {
    ownRecords: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel[] | undefined;
    onRefresh: () => void;
    onClose: () => void;
}

export interface IAddInvoiceRef {
    refresh: () => void;
}

const AddInvoice = React.forwardRef((props: IAddInvoiceProps, ref: React.ForwardedRef<IAddInvoiceRef | undefined>) => {
    const [form] = Form.useForm();
    const [isFormReady, setIsFormReady] = useState(false);
    const { merchantId } = useContext(GlobalContext);
    const { messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [merchantSettings, setMerchantSettings] = useState<Record<string, string> | undefined>(undefined);
    const { mutate } = useMutation(async () => await useGetThirdPartyInvoiceSettingsApi(), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setTpisList(response.result || []);
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const [tpisList, setTpisList] = useState<IThirdPartyInvoiceSetting[] | undefined>(undefined);
    const { mutate: getSupportedCountriesMutate } = useMutation(async () => await useGetSupportedCountriesApi(), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setCountries(response.result || []);
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const [countries, setCountries] = useState<string[]>([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [availableTpis, setAvailableTpis] = useState<IThirdPartyInvoiceSetting[]>([]);

    const handleCountryChange = (country: string) => {
        setSelectedCountry(country);
        form.setFieldValue('selectedTpi', undefined);
        setSelectedTpi(undefined);
        if (!props.ownRecords || !tpisList)
            return;

        var avaliable = tpisList.filter(tpp => !props.ownRecords?.find(record => record.thirdPartySettingId.toString() === tpp.id.toString()));
        if (country === '') {
            setAvailableTpis(avaliable);
        }
        else {
            // Filter TPIS based on selected country
            setAvailableTpis(avaliable.filter(tpi => tpi.supportedCountry === country));
        }
    };

    const { data: addResult, mutate: addMutate } = useMutation(async (request: IAddMerchantThirdPartyInvoiceSettingRequest) => await useAddMerchantThirdPartyInvoiceSettingApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    props.onRefresh();
                    props.onClose();
                }
                else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );
    const [selectedTpi, setSelectedTpi] = useState<IThirdPartyInvoiceSetting | undefined>(undefined);
    const handleSubmit = async () => {
        if (!merchantSettings || !selectedTpi || !merchantId)
            return;
        var request: IAddMerchantThirdPartyInvoiceSettingRequest = {
            settingId: selectedTpi?.id,
            setting: merchantSettings
        };
        addMutate(request);
    };

    const refresh = () => {
        mutate();
        setSelectedTpi(undefined);
        setSelectedCountry('');
        form.setFieldValue('selectedTpi', undefined);
    }

    useEffect(() => {
        getSupportedCountriesMutate();
    }, []);

    useEffect(() => {
        if (tpisList) {
            const filteredTpis = tpisList.filter(tpp => !props.ownRecords?.find(record => record.thirdPartySettingId.toString() === tpp.id.toString()));
            setAvailableTpis(filteredTpis);
        }
    }, [tpisList]);

    useImperativeHandle(ref, () => ({
        refresh: () => {
            refresh();
        }
    }));

    if (!availableTpis)
        return <></>;
    return (
        availableTpis &&
        <>
            <Row justify={'center'}>
                <Empty></Empty>
            </Row>
            <Form form={form} key={merchantSettings?.HashKey}>
                <Form.Item label={translate('Country')}>
                    <Select onChange={handleCountryChange}>
                        {countries && countries.filter(country => country !== 'Unset').map(country => (
                            <Select.Option key={country} value={country}>
                                {country}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="selectedTpi" label={
                    <>
                        {
                            selectedTpi && <InformationPopover providerName={selectedTpi.providerName} />
                        }
                        {translate('Invoice')}
                    </>
                }>
                    <Select onChange={(id: string) => {
                        const selectedTpi = availableTpis.find(tpi => tpi.id.toString() === id.toString());
                        setSelectedTpi(selectedTpi);
                    }}>
                        {availableTpis && availableTpis.map(tpi => (
                            <Select.Option key={tpi.id.toString()} value={tpi.id.toString()}>
                                {tpi.providerName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form >
            {/* if seleted tpp */}
            {
                selectedTpi &&
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    {Object.keys(selectedTpi.merchantFillFieldsForBasicInfo).map((value, index, array) => (
                        <Form.Item
                            key={selectedTpi.merchantFillFieldsForBasicInfo[index]}
                            name={selectedTpi.merchantFillFieldsForBasicInfo[index]}
                            label={translate(selectedTpi.merchantFillFieldsForBasicInfo[index])}
                        >
                            <Row justify="space-between">
                                <Col span={20}>
                                    <Input
                                        onChange={(e) =>
                                            setMerchantSettings({ ...merchantSettings, [selectedTpi.merchantFillFieldsForBasicInfo[index]]: e.target.value })
                                        }
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    ))}
                    <Row justify={'center'}>
                        <Form.Item>
                            <Button type="primary" onClick={() => { handleSubmit() }}>
                                {translate('Add')}
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            }
        </>
    );
});

export default AddInvoice;