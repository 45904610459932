
import { IAddMerchantThirdPartyInvoiceSettingRequest, IAlterMerchantThirdPartyInvoiceSettingRequest, ICreateThirdPartyOrderRequest, IGetBackOfficeOrderViewModelRequest, IGetThirdPartyOrdersByPlatformOrderRequest, IQueryThirdPartyOrderStatusRequest, IThirdPartyInvoiceQueryStatusResponse } from "@/interfaces/Requests/Requests";
import { IPrintThirdPartyInvoiceRequest } from "@/interfaces/Requests/ThirdPartyInvoice";
import { IBackOfficeMerchantThirdPartyInvoiceSettingViewModel, IBackOfficeOrderViewModel } from "@/interfaces/Responses/Responses";
import { IThirdPartyInvoiceOrderViewModel, IThirdPartyInvoicePrintResponse, IThirdPartyInvoiceSetting, ThirdPartyInvoiceCreateRespnse } from "@/interfaces/Responses/ThirdPartyInvoice";
import { IOperationResult, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import useAPI from '@/Templates/lib/customHooks/useAPI';

export const useGetThirdPartyInvoiceSettingsApi = () => useAPI<IOperationResultT<IThirdPartyInvoiceSetting[]>>(`${process.env.BASE_API_URL}/api/ThirdPartyInvoice/GetThirdPartyInvoiceSettings`);

export const useGetThirdPartyInvoiceSettingApi = (settingId: BigInt) => useAPI<IOperationResultT<IThirdPartyInvoiceSetting>>(`${process.env.BASE_API_URL}/api/ThirdPartyInvoice/GetThirdPartyInvoiceSetting?settingId=${settingId.toString()}`);

export const useGetBackOfficeMerchantThirdPartyInvoiceSettingViewModelsApi = (signal?: AbortSignal) => useAPI<IOperationResultT<IBackOfficeMerchantThirdPartyInvoiceSettingViewModel[]>>(`${process.env.BASE_API_URL}/api/ThirdPartyInvoice/GetBackOfficeMerchantThirdPartyInvoiceSettingViewModels`, {
    signal: signal
});

export const useAddMerchantThirdPartyInvoiceSettingApi = (request: IAddMerchantThirdPartyInvoiceSettingRequest, signal?: AbortSignal) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ThirdPartyInvoice/AddMerchantThirdPartyInvoiceSetting`, {
    method: 'POST',
    body: request,
    signal: signal
});

export const useAlterMerchantThirdPartyInvoiceSettingApi = (request: IAlterMerchantThirdPartyInvoiceSettingRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/ThirdPartyInvoice/AlterMerchantThirdPartyInvoiceSetting`, {
    method: 'POST',
    body: request
});

export const useGetBackOfficeOrderViewModelApi = (request: IGetBackOfficeOrderViewModelRequest, signal?: AbortSignal) => useAPI<IOperationResultT<IBackOfficeOrderViewModel>>(`${process.env.BASE_API_URL}/api/Order/GetBackOfficeOrderViewModel`, {
    method: 'GET',
    body: request,
    signal: signal
});

export const useCreateThirdPartyInvoiceOrderApi = (request: ICreateThirdPartyOrderRequest, signal?: AbortSignal) => useAPI<IOperationResultT<ThirdPartyInvoiceCreateRespnse>>(`${process.env.BASE_API_URL}/api/ThirdPartyInvoice/CreateOrder`, {
    method: 'POST',
    body: request,
    signal: signal
});

export const useGetThirdPartyOrdersByPlatformOrderApi = (request: IGetThirdPartyOrdersByPlatformOrderRequest, signal?: AbortSignal) => useAPI<IOperationResultT<IThirdPartyInvoiceOrderViewModel[]>>(`${process.env.BASE_API_URL}/api/ThirdPartyInvoice/GetThirdPartyOrdersByPlatformOrder`, {
    method: 'GET',
    body: request,
    signal: signal
});

export const useQueryInvoiceOrderStatusApi = (request: IQueryThirdPartyOrderStatusRequest, signal?: AbortSignal) => useAPI<IOperationResultT<IThirdPartyInvoiceQueryStatusResponse>>(`${process.env.BASE_API_URL}/api/ThirdPartyInvoice/QueryThirdPartyOrderStatus`, {
    method: 'POST',
    body: request,
    signal: signal
});

export const usePrintInvoiceApi = (request: IPrintThirdPartyInvoiceRequest, signal?: AbortSignal) => useAPI<IOperationResultT<IThirdPartyInvoicePrintResponse>>(`${process.env.BASE_API_URL}/api/ThirdPartyInvoice/PrintInvoice`, {
    method: 'POST',
    body: request,
    signal: signal
});