import { IAlterMerchantThirdPartyLogisticSettingRequest } from '@/interfaces/Requests/Requests';
import { IBackOfficeMerchantThirdPartyLogisticSettingViewModel } from '@/interfaces/Responses/Responses';
import { IThirdPartyLogisticSetting } from '@/interfaces/Responses/ThirdPartyLogistic';
import { useAlterMerchantThirdPartyLogisticSettingApi, useGetThirdPartyLogisticSettingApi } from '@/lib/api/thirdPartyLogistics';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { Button, Form, Input, Row, Typography } from 'antd';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useMutation } from 'react-query';

const { Title } = Typography;

export interface IEditLogisticSettingProps {
    onRefresh: () => void;
    onClose: () => void;
}

export interface IEditLogisticSettingRef {
    onOpen: (record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel) => void;
    onClose: () => void;
}

const EditLogistic = React.forwardRef((props: IEditLogisticSettingProps, ref: React.ForwardedRef<IEditLogisticSettingRef | undefined>) => {
    const [form] = Form.useForm();
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [vm, setVm] = useState<IBackOfficeMerchantThirdPartyLogisticSettingViewModel | undefined>(undefined);
    const [tpls, setTpls] = useState<IThirdPartyLogisticSetting | undefined>(undefined);

    const { mutate: fetchTplsMutate } = useMutation(
        async (settingId: BigInt) => await useGetThirdPartyLogisticSettingApi(settingId),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    setTpls(response.result);
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const { mutate: alterMutate, isLoading } = useMutation(
        async (request: IAlterMerchantThirdPartyLogisticSettingRequest) => await useAlterMerchantThirdPartyLogisticSettingApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    props.onRefresh();
                    props.onClose();
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const reset = () => {
        setTpls(undefined);
        setVm(undefined);
        form.resetFields();
    };

    useImperativeHandle(ref, () => ({
        onOpen: (record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel) => {
            if (record) {
                fetchTplsMutate(record.thirdPartySettingId);
                setVm(record);
            }
        },
        onClose: () => {
            reset();
        }
    }));

    useEffect(() => {
        if (tpls && vm) {
            const initialMerchantSettings = tpls.merchantFillFieldsForBasicInfo.reduce((acc: Record<string, string>, field) => {
                acc[field] = vm.merchantSettings?.[field] || '';
                return acc;
            }, {});
            form.setFieldsValue(initialMerchantSettings);
        }
    }, [tpls, vm, form]);

    const handleSubmit = () => {
        if (!merchantId || !vm) return;
        const formValues = form.getFieldsValue();
        const request: IAlterMerchantThirdPartyLogisticSettingRequest = {
            settingId: vm.thirdPartySettingId,
            setting: formValues,
            isDisabled: vm.isDisable,
        };
        alterMutate(request);
    };

    if (!tpls || !vm) return <LoadingComponent></LoadingComponent>;

    return (
        <>
            <Row justify="center">
                <Title level={4}>
                    {translate(`${tpls.providerName}`)} {translate('Parameters')}
                </Title>
            </Row>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                {tpls.merchantFillFieldsForBasicInfo.map((key) => (
                    <Form.Item
                        key={key}
                        name={key}
                        label={translate(key)}
                    >
                        <Input />
                    </Form.Item>
                ))}
                <Row justify="center">
                    <Form.Item>
                        <Button
                            type="primary"
                            loading={isLoading}
                            onClick={handleSubmit}
                        >
                            {translate('Save')}
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </>
    );
});

export default EditLogistic;