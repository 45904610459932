import { ITranslationContext, ITranslationContextProps } from '@/interfaces/ITranslationContext';
import { useGetGetTranslationsApi, useGetSupportedLanguagesApi } from '@/lib/api/portals';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// i18next 初始化和配置代码保持不变
i18n
    .use(initReactI18next)
    .init({
        // 资源，语言配置等
    });

export const TranslationContext = React.createContext<ITranslationContext>(null!);

export const TranslationProvider: React.FC<ITranslationContextProps> = React.memo(({ children }): React.ReactNode => {
    const defaultNameSpace = 'Global';
    const { t, i18n } = useTranslation(defaultNameSpace);
    const [supportedLanguages, setSupportedLanguages] = useState<string[]>(['en_US', 'zh_TW']);
    const [language, setLanguage] = useState<string>('zh_TW');
    const [translationCache, setTranslationCache] = useState<Record<string, Record<string, Record<string, string>>>>({});

    const customTranslate = useCallback((key: string, nameSpace: string = defaultNameSpace): string => {
        if (!key) return '';
        const translatedString = i18n.t(key.toLowerCase(), { ns: nameSpace });
        return translatedString === key.toLowerCase() ? key : translatedString;
    }, [i18n]);

    // 獲取支持的語言
    useEffect(() => {
        const fetchSupportedLanguages = async () => {
            try {
                const response = await useGetSupportedLanguagesApi();
                if (response.isSuccess && response.result) {
                    setSupportedLanguages(response.result);
                } else {
                    throw new Error('獲取支持的語言失敗');
                }
            } catch (error) {
                console.error('獲取支持的語言失敗:', error);
                // 5秒後重試
                setTimeout(fetchSupportedLanguages, 5000);
            }
        };

        fetchSupportedLanguages();
    }, []);

    // 获取翻译数据
    useEffect(() => {
        const fetchTranslations = async () => {
            if (translationCache[language]) {
                // 如果缓存中已有该语言的翻译，直接使用缓存
                Object.entries(translationCache[language]).forEach(([ns, translations]) => {
                    i18n.addResourceBundle(language, ns, translations, true, true);
                });
                i18n.changeLanguage(language);
                return;
            }

            try {
                const response = await useGetGetTranslationsApi(language);
                if (!response.isSuccess || !response.result) {
                    throw new Error('獲取翻譯數據失敗');
                }

                const dtoTranslation = response.result.reduce((acc, translation) => {
                    if (!acc[translation.nameSpace]) {
                        acc[translation.nameSpace] = {};
                    }
                    acc[translation.nameSpace][translation.key] = translation.value;
                    return acc;
                }, {} as Record<string, Record<string, string>>);

                // 更新缓存
                setTranslationCache(prev => ({
                    ...prev,
                    [language]: dtoTranslation
                }));

                Object.entries(dtoTranslation).forEach(([ns, translations]) => {
                    i18n.addResourceBundle(language, ns, translations, true, true);
                });
                i18n.changeLanguage(language);
            } catch (error) {
                console.error('获取翻译数据失败:', error);
                // 5秒後重試
                setTimeout(fetchTranslations, 5000);
            }
        };

        fetchTranslations();
    }, [language]);

    const contextValue = useMemo(() => ({
        supportedLanguages,
        language,
        seti18nLanguage: setLanguage,
        i18nLanguage: i18n.language,
        translate: customTranslate
    }), [supportedLanguages, language, i18n.language, customTranslate]);

    return (
        <TranslationContext.Provider value={contextValue}>
            {children}
        </TranslationContext.Provider>
    );
});